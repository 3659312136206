import { SelectionModel } from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { STChange, STColumn, STColumnFilter, STColumnFilterHandle, STColumnFilterMenu, STComponent, STData } from '@delon/abc/st';
import { ACLService } from '@delon/acl';
import { Layout, SettingsService, User } from '@delon/theme';
import { TranslateService } from '@ngx-translate/core';
import { QueryRef } from 'apollo-angular';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { MosaicAppSettings } from 'src/app/core/app.state';
import { DocumentClass, DocumentClassSortFields } from 'src/app/graphql/data-graphql';
import {
  Document,
  DocumentFilter,
  DocumentSort,
  DocumentSortFields,
  DocumentsQuery,
  DocumentsQueryVariables,
  DocumentState,
  PlatformRole,
  SortDirection,
  SourceChannel
} from 'src/app/graphql/frontend-data-graphql';
import { PagingMode } from 'src/app/shared/components/entity-list/entity-list.component';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DateService } from 'src/app/shared/services/date.service';

import { DocumentClassService } from '../../services/document-class.service';
import { DocumentDataSource } from '../../services/document-data-source';
import { DocumentService } from '../../services/document.service';
import { SubdocumentDrawerComponent } from './subdocument-drawer/subdocument-drawer.component';

const TABLE_WIDTH = 1400;

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentListComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private messageService: NzMessageService,
    private http: HttpClient,
    public documentService: DocumentService,
    public auth: AuthService,
    public dateService: DateService,
    private router: Router,
    private route: ActivatedRoute,
    private acl: ACLService,
    private msg: NzMessageService,
    public translate: TranslateService,
    private _viewContainerRef: ViewContainerRef,
    public documentClassService: DocumentClassService,
    private settings: SettingsService<Layout, User, MosaicAppSettings>
  ) {}

  get now() {
    return new Date();
  }
  @Input()
  archiveMode = false; // set to true, if the list should only show deleted items
  @Input()
  showSubdocuments = true;
  @Input()
  isSubdocumentDrawerOpen = false;
  @Input()
  filter$: Observable<DocumentFilter>;
  @Input()
  training = false;
  @Input()
  hideFields: string[];
  @Input()
  clear$: Observable<boolean>;
  @Input()
  pageSize = 50;
  @Output()
  readonly currentListChanged = new EventEmitter<Document[]>();
  @Input()
  showResetButton = false;
  @ViewChild('table', { static: false })
  table: STComponent;
  @ViewChild('documentClassFilter', { static: false })
  documentClassFilter: TemplateRef<{
    $implicit: STColumnFilter;
    col: STColumn;
    handle: STColumnFilterHandle;
  }>;
  @ViewChild('customCreatedFilter', { static: false })
  customCreatedFilter: TemplateRef<{
    $implicit: STColumnFilter;
    col: STColumn;
    handle: STColumnFilterHandle;
  }>;
  @ViewChild('customUpdatedFilter', { static: false })
  customUpdatedFilter: TemplateRef<{
    $implicit: STColumnFilter;
    col: STColumn;
    handle: STColumnFilterHandle;
  }>;

  @Output()
  readonly customFilterChanged = new EventEmitter<DocumentFilter>();

  loading = false;
  PagingMode = PagingMode;
  documents$: Observable<Document[]>;
  Date = Date;
  documentsQuery$: Observable<QueryRef<DocumentsQuery, DocumentsQueryVariables>>;
  documents?: DocumentDataSource;
  someDocumentsDeleted = false;
  columns$: Observable<STColumn[]>;
  selectedDocuments = new SelectionModel<Document>(true, [], undefined, (o1, o2) => o1.id == o2.id);
  PlatformRole = PlatformRole;
  isProcessing = false;
  DocumentState = DocumentState;
  sorting$: Observable<DocumentSort[]>;
  private readonly destroy$ = new Subject();
  scrollY$ = new BehaviorSubject<string>('800px');
  widthX: number;
  documentClasses$: Observable<Record<string, DocumentClass>>;
  checkedDocument$: Observable<Document[]>;
  customFilter$: Observable<DocumentFilter>;

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    const rect = this._viewContainerRef.element.nativeElement.getBoundingClientRect();
    setTimeout(() => {
      this.scrollY$.next(`${window.innerHeight - rect.y - 54}px`);
    });
  }

  restoreScrollState() {
    setTimeout(() => {
      if (this.documents) this.table.cdkVirtualScrollViewport.scrollToIndex(this.documents.scrollIndex$.value);
    }, 5);
  }

  ngOnInit(): void {
    // Calculate width of table
    const columnWidth = (TABLE_WIDTH - 360) / 5;
    this.widthX = this.hideFields ? TABLE_WIDTH - this.hideFields.length * columnWidth : TABLE_WIDTH;
    if (!this.auth.acl.can([PlatformRole.Developer])) {
      this.widthX -= 2 * columnWidth;
    }
  }

  viewport$ = new Subject<CdkVirtualScrollViewport>();
  ngAfterViewInit() {
    this.documentClasses$ = this.documentClassService
      .watchDocumentClasses(
        {},
        { first: 100000 },
        { field: DocumentClassSortFields.DisplayName, direction: SortDirection.Asc },
        false,
        true
      )
      .pipe(takeUntil(this.destroy$));
    this.columns$ = combineLatest([
      this.filter$,
      this.documentService.dateFilter$,
      this.documentService.nameFilter$,
      this.documentService.classFilter$,
      this.documentService.sourceFilter$
    ])
      .pipe(
        map(
          ([filter, dateFilter, nameFilter, classFilter, sourceFilter]) => (<STColumn[]>[
              {
                title: 'Checkbox',
                index: 'id.value',
                type: 'checkbox',
                width: 35
              },
              {
                title: this.translate.instant('DOCUMENTLIST.documentName'),
                index: 'title',
                render: 'document_id',
                filter: {
                  type: 'keyword',
                  menus:
                    nameFilter && nameFilter != ''
                      ? [
                          {
                            value: nameFilter
                          }
                        ]
                      : [],
                  placeholder: 'Name'
                },
                sort: true,
                fixed: 'true'
              },
              {
                title: '',
                render: 'blockedAndSnoozed',
                width: 35
              },
              {
                title: this.translate.instant('DOCUMENTLIST.documentState'),
                index: 'state',
                render: 'documentState',
                sort: true,
                filter: {
                  menus: filter.state?.in?.map(state => ({ text: state, value: state })) ?? [],
                  fn: (filter: STColumnFilterMenu, record: Document) => (filter.value as string[]).includes(record.state),
                  multiple: true
                }
              },
              {
                title: this.translate.instant('DOCUMENTLIST.documentClass'),
                render: 'documentClass',
                index: 'document_class_identifier',
                filter: {
                  type: 'custom',
                  custom: this.documentClassFilter,
                  icon: { type: 'search', theme: 'outline' },
                  showOPArea: false,
                  multiple: true,
                  fn: (filter: STColumnFilterMenu, record: Document) =>
                    !filter.value || record.document_class_identifier.indexOf(filter.value) !== -1,
                  menus: classFilter?.length > 0 ? classFilter.map(c => ({ value: c })) : undefined
                },
                sort: true
              },
              {
                title: this.translate.instant('DOCUMENTLIST.pages'),
                index: 'pages',
                format: (document: Document) => (document?.page_count > 0 ? document?.page_count.toString() : '?'),
                sort: {
                  compare: (a: Document, b: Document) => a.page_count - b.page_count
                },
                width: 70
              },
              {
                title: this.translate.instant('DOCUMENTLIST.root'),
                index: 'root_document_id',
                render: 'root',
                sort: true,
                acl: {
                  role: [PlatformRole.Developer]
                }
              },
              {
                title: this.translate.instant('DOCUMENTLIST.parent'),
                index: 'parent_document_id',
                render: 'parent',
                sort: true,
                acl: {
                  role: [PlatformRole.Developer]
                }
              },

              this.showSubdocuments
                ? {
                    title: this.translate.instant('DOCUMENTLIST.subdocuments'),
                    index: 'children',
                    buttons: [
                      {
                        icon: 'file-search',
                        iif: (item: Document) => item.children && item.children.length > 0, // True dann wird angezeigt
                        iifBehavior: 'disabled',
                        text: (item: Document) => {
                          if (!item.children) return;
                          const amount: number = item.children.length;
                          switch (amount) {
                            case 0:
                              return `${this.translate.instant('DOCUMENTLIST.noSubDocuments')}`;
                            case 1:
                              return `1 ${this.translate.instant('DOCUMENTLIST.document')}`;
                            default:
                              return `${amount} ${this.translate.instant('DOCUMENTLIST.documents')}`;
                          }
                        },
                        type: 'drawer',
                        drawer: {
                          title: this.translate.instant('DOCUMENTLIST.subdocuments'),
                          component: SubdocumentDrawerComponent,
                          params: (item: Document) => ({ id: item.id }),
                          drawerOptions: {
                            nzWidth: '1000px'
                          }
                        }
                      }
                    ]
                  }
                : null,
              {
                title: this.translate.instant('DOCUMENTLIST.source'),
                index: 'source',
                sort: true,
                width: 100,
                filter: {
                  menus:
                    Object.values(SourceChannel).map(source => ({ text: source, value: source, checked: sourceFilter.includes(source) })) ??
                    [],
                  fn: (filter: STColumnFilterMenu, record: Document) => (filter.value as string[]).includes(record.source as any),
                  multiple: true
                }
              },
              {
                title: this.translate.instant('DOCUMENTLIST.created'),
                index: 'created',
                sort: true,
                render: 'createdDate',
                className: 'date',
                width: 150,
                filter: {
                  type: 'custom',
                  custom: this.customCreatedFilter,
                  icon: { type: 'clock-circle', theme: 'outline' },
                  showOPArea: false,
                  menus: dateFilter?.created?.between
                    ? [
                        {
                          value: dateFilter?.created?.between?.lower
                        },
                        { value: dateFilter?.created?.between?.upper }
                      ]
                    : []
                }
              },
              {
                title: this.translate.instant('DOCUMENTLIST.lastEdited'),
                index: 'updated',
                sort: true,
                render: 'editedDate',
                className: 'date',
                width: 150,
                filter: {
                  type: 'custom',
                  custom: this.customUpdatedFilter,
                  icon: { type: 'clock-circle', theme: 'outline' },
                  showOPArea: false,
                  menus: dateFilter?.updated?.between
                    ? [
                        {
                          value: dateFilter?.updated?.between?.lower
                        },
                        { value: dateFilter?.updated?.between?.upper }
                      ]
                    : []
                }
              }
            ].filter(c => c && !this.hideFields?.includes(c.index ?? ''))) as STColumn[]
        )
      )
      .pipe(takeUntil(this.destroy$));

    this.columns$.subscribe(c => this.table.resetColumns({ columns: c, emitReload: true, preClearData: true }));
    this.onResize();
    setTimeout(() => {
      this.sorting$ = this.table.change.pipe(
        filter(change => change.sort != undefined),
        filter(change => change.sort?.column?.index != 'pages'),
        map(change => {
          return [
            {
              field: (change.sort?.column?.index as any)[0],
              direction: change.sort!.value === 'ascend' ? SortDirection.Asc : SortDirection.Desc
            }
          ];
        }),
        startWith([{ field: DocumentSortFields.Updated, direction: SortDirection.Desc }]),
        takeUntil(this.destroy$)
      );

      this.customFilter$ = combineLatest([
        this.documentService.nameFilter$,
        this.documentService.classFilter$,
        this.documentService.dateFilter$,
        this.documentService.sourceFilter$
      ]).pipe(
        map(([nameFilter, classFilter, dateFilter, sourceFilter]) => {
          const filters: DocumentFilter[] = [];
          if (nameFilter && nameFilter.length > 0) {
            filters.push({
              or: [{ title: { like: `%${nameFilter}%` } }, { id: { like: `%${nameFilter}%` } }]
            });
          }
          if (classFilter.length > 0) {
            filters.push({
              document_class_identifier: { in: classFilter }
            });
          }
          if (dateFilter && Object.values(dateFilter).filter(v => v != undefined).length > 0) {
            filters.push(dateFilter);
          }
          if (sourceFilter?.length > 0) {
            filters.push({
              source: { in: sourceFilter }
            });
          }
          return filters?.length > 0 ? { and: filters } : {};
        }),
        startWith({}),
        shareReplay(1)
      );

      this.table.change
        .pipe(filter(change => change.type == 'filter'))
        .pipe(
          map(change => {
            const column = change.filter;
            if (!column) return;
            if (column.filter?.key == 'title') {
              this.documentService.nameFilter$.next(column.filter?.menus![0].value);
            } else if (column.filter?.key == 'source') {
              this.documentService.sourceFilter$.next(column.filter?.menus?.filter(m => m.checked).map(m => m.value) ?? []);
            }
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();

      this.customFilter$.subscribe(res => this.customFilterChanged.emit(res));

      const allFilters$ = combineLatest([this.filter$, this.customFilter$]).pipe(
        map(([externalFilter, customFilter]) => {
          return { and: [externalFilter, customFilter] };
        }),
        takeUntil(this.destroy$)
      );
      this.documents = new DocumentDataSource({
        filter: allFilters$,
        sorting: this.sorting$,
        documentService: this.documentService,
        viewport: this.viewport$,
        destroy: this.destroy$,
        pageSize: 25
      });
      this.documents.data$.pipe(takeUntil(this.destroy$)).subscribe(data => this.currentListChanged.emit(data));
      this.viewport$.next(this.table.cdkVirtualScrollViewport!);

      this.checkedDocument$ = this.documents.data$.pipe(
        map(documents => {
          documents.forEach((doc: Document & STData, index) => {
            doc['checked'] = this.selectedDocuments.isSelected(doc);
          });
          return documents;
        }),
        takeUntil(this.destroy$)
      );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  componentIsInactive = false;
  _onReuseInit() {
    this.componentIsInactive = false;
  }
  _onReuseDestroy() {
    this.componentIsInactive = true;
  }

  change(e: STChange): void {
    if (e.type === 'checkbox') {
      this.selectedDocuments.clear();
      this.selectedDocuments.select(...(e.checkbox as Document[]));
      this.someDocumentsDeleted = e?.checkbox?.map(e => Boolean(e.deleted)).reduce((a, b) => a || b, false) ?? false;
    }
  }
  virtualForTrackBy: TrackByFunction<Document> = (i: number, a: Document) => a['id'];

  tmpClassFilter = [];
  classFilterChanged(f: STColumnFilter, handle: STColumnFilterHandle, result: boolean) {
    if (result) {
      this.documentService.classFilter$.next(this.tmpClassFilter);
      f.menus = this.documentService.classFilter$.value.map(f => ({ value: f }));
      handle.close(result);
    } else {
      handle.close(result);
      delete f.menus;
      this.documentService.classFilter$.next([]);
    }
  }

  isTextOverflow(elementId: string): boolean {
    const element = document.getElementById(elementId);
    if (!element) return false;
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }
  clearSelection() {
    this.table.clearCheck();
  }
  dateFilterVisible = false;
  tmpDateFilter = [];
  applyDateRangeFilter(f: STColumnFilter, handle: STColumnFilterHandle, result: boolean, key: 'created' | 'updated' = 'created') {
    if (result) {
      console.log(key);
      this.documentService.dateFilter$.next({
        ...this.documentService.dateFilter$.value,
        [key]: { between: { lower: this.tmpDateFilter[0], upper: this.tmpDateFilter[1] } }
      });
      const dateFilter = this.documentService.dateFilter$.value;
      if (dateFilter && dateFilter[key]?.between)
        f.menus = [{ value: dateFilter[key]!.between!.lower }, { value: dateFilter[key]!.between!.upper }];
      handle.close(result);
    } else {
      handle.close(result);
      this.clearDateFilter(key);
      delete f.menus;
    }
  }
  clearDateFilter(key: 'created' | 'updated') {
    this.documentService.dateFilter$.next({ ...this.documentService.dateFilter$.value, [key]: undefined });
  }
}
