import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event, PRIMARY_OUTLET } from '@angular/router';
import { StartupService } from '@core';
import { ACLService } from '@delon/acl';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { Layout, SettingsService, User } from '@delon/theme';
import { LayoutDefaultOptions } from '@delon/theme/layout-default';
import { environment } from '@env/environment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, skip, switchMap, take, timer } from 'rxjs';
import { MosaicAppSettings } from 'src/app/core/app.state';
import { FreshdeskService } from 'src/app/freshdesk.service';
import { Organization } from 'src/app/graphql/data-graphql';
import { Scope } from 'src/app/graphql/frontend-data-graphql';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OrganizationService } from 'src/app/shared/services/organization.service';

import packageVersion from '../../../../package.json';
import { HelperService } from './widgets/helper/helper.service';

@Component({
  selector: 'layout-basic',
  template: `
    <layout-default [options]="options" [nav]="nav" [content]="contentTpl" [customError]="null">
      <layout-default-header-item direction="left">
        <h3 style="position: relative; top: 3px; color: white">{{ environment['platform_label'] }}</h3>
      </layout-default-header-item>

      <layout-default-header-item>
        <nz-spin style="width:24px" nzSimple [nzSpinning]="authService.refreshToking" [nzIndicator]="indicatorTemplate">
          <ng-template #indicatorTemplate>
            <span style="color:white" nz-icon nzType="loading"></span>
          </ng-template>
        </nz-spin>
      </layout-default-header-item>
      <layout-default-header-item direction="right">
        <nz-alert
          *ngIf="environment.platform_warning"
          style="display:inline-block;margin-right:24px"
          nzBanner
          [nzMessage]="environment.platform_warning"></nz-alert>

        <nz-badge
          [nzStatus]="(backendStatus$ | async) ?? 'error'"
          [nzText]="'BASIC.state' | translate"
          nz-popover
          [nzPopoverContent]="packageVersion"
          [nzPopoverTrigger]="'hover'"></nz-badge>
      </layout-default-header-item>
      <layout-default-header-item direction="right">
        <header-search class="alain-default__search" [toggleChange]="searchToggleStatus"></header-search>
      </layout-default-header-item>

      <layout-default-header-item *ngIf="organizations$ | async; let organizations" direction="right">
        <div
          *let="authService.organization$ | async as organization"
          class="alain-default__nav-item d-flex align-items-center px-sm headerbar"
          nz-dropdown
          [nzDropdownMenu]="menu">
          <i style="margin-right: 6px" nz-icon nzType="bank" nzTheme="outline"></i>

          <span>
            {{ organization?.name }}
            <i nz-icon nzType="down"></i>
          </span>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li
                [nzSelected]="o.identifier === organization?.identifier"
                nz-menu-item
                *ngFor="let o of organizations"
                (click)="switchOrganization(o)">
                <span [ngClass]="{ 'font-weight-bold': o.identifier === organization?.identifier }">{{ o.name }}</span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </layout-default-header-item>
      <layout-default-header-item *ngIf="authService.scope$ | async as scope" direction="right">
        <div
          [hidden]="!showScopeSelector"
          nz-dropdown
          class="alain-default__nav-item d-flex align-items-center px-sm headerbar"
          [nzDropdownMenu]="menu"
          [nzDisabled]="availableScopes.length < 2">
          <i style="margin-right: 6px" nz-icon nzType="copy" nzTheme="outline"></i>

          <span>
            {{ scope | translateScope | translate }}
            <i *ngIf="(availableScopes?.length ?? 0) > 1" nz-icon nzType="down"></i>
          </span>
          <nz-dropdown-menu #menu="nzDropdownMenu">
            <ul nz-menu nzSelectable>
              <li [nzSelected]="s === scope" nz-menu-item *ngFor="let s of availableScopes" (click)="scopeChange(s)">
                <span [ngClass]="{ 'font-weight-bold': s === scope }">{{ s | translateScope | translate }}</span>
              </li>
            </ul>
          </nz-dropdown-menu>
        </div>
      </layout-default-header-item>

      <layout-default-header-item direction="right">
        <header-user></header-user>
      </layout-default-header-item>
      <ng-template #nav>
        <layout-navigation class="d-block py-lg"></layout-navigation>
      </ng-template>
      <ng-template #contentTpl>
        <router-outlet></router-outlet>
      </ng-template>
    </layout-default>
  `,
  styles: [
    `
      :host ::ng-deep .ant-badge-status-text {
        color: white;
      }
      [hidden] {
        display: none !important;
      }
    `
  ]
})
export class LayoutBasicComponent implements OnInit {
  options: LayoutDefaultOptions = {
    logoExpanded: `./assets/logos/lector-ai-logo-white.svg`,
    logoCollapsed: `./assets/logos/lector-ai-icon-white.svg`
  };
  searchToggleStatus = false;
  showSettingDrawer = !environment.production;
  showFreshworkWidget: BehaviorSubject<boolean>;
  showScopeSelector = true;
  environment = environment;

  packageVersion: string;

  organizations$: Observable<Array<Partial<Organization>>>;
  availableScopes: Scope[] = [Scope.Production, Scope.Development, Scope.Training, Scope.Testing, Scope.HealthCheck];
  backendStatus$: Observable<'success' | 'error'>;
  docId: string;

  get user(): User {
    return this.settings.user;
  }

  constructor(
    private settings: SettingsService<Layout, User, MosaicAppSettings>,
    public authService: AuthService,
    public helperService: HelperService,
    public acl: ACLService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private messageServer: NzMessageService,
    private freshdeskService: FreshdeskService,
    private http: HttpClient,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private organizationService: OrganizationService,
    private startupService: StartupService
  ) {
    this.startupService.initialize();
    this.packageVersion = (packageVersion as any).version;

    this.authService.availableScopes$.subscribe(as => {
      this.availableScopes = as;
    });

    this.backendStatus$ = timer(50, 30000).pipe(
      switchMap(_ => {
        // TODO: Fix backend health check
        return this.http.get(`${environment.api.baseUrl}`, { responseType: 'text' }).pipe(
          map(res => {
            return 'success';
          }),
          catchError(err => {
            console.error(err);
            console.error('Caught error');
            return of('error');
          })
        );
      })
    ) as Observable<'success' | 'error'>;

    // Make sure the query params are always up to date
    combineLatest([
      this.authService.scope$.pipe(filter(o => o != undefined)),
      this.authService.organization$.pipe(filter(o => o != undefined))
    ]).subscribe(([scope, organization]) => {
      this.activatedRoute.queryParams.pipe(take(1)).subscribe(currentQueryParams => {
        if (currentQueryParams['organization'] == organization?.identifier && currentQueryParams['scope'] == scope?.toString()) return;
        this.router.navigate([], {
          queryParams: {
            organization: organization?.identifier,
            scope: scope?.toString()
            // NOTE: do not set the id here; we need it to be preserved (by 'merge' down below)
          },
          queryParamsHandling: 'merge',
          relativeTo: this.activatedRoute
        });
      });
    });

    // Hide selectors in administration views
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let path = this.router.parseUrl(event.url).root.children[PRIMARY_OUTLET]?.segments[0].path;
        this.showScopeSelector = path === 'admin' ? false : true;
      }
    });
  }

  ngOnInit() {
    this.organizations$ = this.organizationService.getOrganizationObservable();
    combineLatest([this.authService.organization$, this.organizations$]).subscribe(([org, orgs]) => {
      const resolvedOrg = orgs.find(o => o?.identifier == org?.identifier);
      if (resolvedOrg) this.freshdeskService.setFreshworkWidgetVisibility(resolvedOrg as any);
    });
  }

  switchOrganization(o: Partial<Organization>) {
    this.authService.switchOrganization(o);
  }

  scopeChange(scope: Scope) {
    this.authService.scope$.next(scope);
  }
  compareFn = (a: Organization, b: Organization) => a?.identifier == b?.identifier;
}
