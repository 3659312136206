import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Document } from '../../../graphql/frontend-data-graphql';

@Component({
  selector: 'app-document-tree-viewer-drawer',
  templateUrl: './document-tree-viewer-drawer.component.html',
  styles: []
})
export class DocumentTreeViewerDrawerComponent implements OnInit {
  @Input()
  visible: boolean = false;
  @Input()
  documents: SelectionModel<Document>;
  @Input()
  showRootByDefault: boolean = false;

  @Output()
  readonly visibleChange = new EventEmitter<boolean>();

  showRoot$ = new BehaviorSubject<boolean>(false);
  showDeleted$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    this.showRoot$.next(this.showRootByDefault);
  }
}
