import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { finalize, take } from 'rxjs/operators';
import { DocumentState, Document } from 'src/app/graphql/frontend-data-graphql';
import { DocumentWithLevel } from 'src/app/shared/components/document-tree-viewer/document-tree-viewer.component';

import { DocumentService } from '../../services/document.service';

// return a promise
function copyToClipboard(textToCopy: any) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  } else {
    // text area method
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res(true) : rej();
      textArea.remove();
    });
  }
}

@Component({
  selector: 'app-document-modifier-bar',
  templateUrl: './document-modifier-bar.component.html',
  styleUrls: ['./document-modifier-bar.component.less']
})
export class DocumentModifierBarComponent implements OnInit {
  @Input()
  selection = new SelectionModel<Document>(true);
  @Input()
  showResetButton = false;
  @Input()
  showSnooze = true;
  @Input()
  showTreeButton = false;
  @Output()
  readonly isProcessing = new EventEmitter<boolean>(false);
  @Output()
  readonly cleared = new EventEmitter<boolean>();

  treeVisible = false;

  get selectedIds() {
    return this.selection.selected.map(d => d.id);
  }
  triggerableStates = Object.values(DocumentState).filter(state => ![DocumentState.Created].includes(state));

  constructor(private documentService: DocumentService, private msgService: NzMessageService, public translate: TranslateService) {}

  ngOnInit(): void {
    console.log('Initialized');
  }

  async deleteSelected() {
    this.isProcessing.emit(true);

    try {
      await this.documentService.softDeleteDocuments(this.selectedIds);
      this.msgService.info('Finished deleting.');
    } catch (err) {
      this.msgService.error('Unable to delete documents(s).');

      console.error('Unable to delete documents(s)');
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing.emit(false);
    });
  }

  async hardDeleteSelected() {
    this.isProcessing.emit(true);

    try {
      const rootDocIds = this.selection.selected.filter(d => d.id == d.root_document_id).map(d => d.id);
      await this.documentService.hardDeleteDocumentsGQL.mutate({ document_ids: rootDocIds, count: rootDocIds.length }).toPromise();
      this.msgService.info(
        this.selectedIds.length - rootDocIds.length > 0
          ? `Finished deleting ${rootDocIds.length} documents, ${
              this.selectedIds.length - rootDocIds.length
            } documents were non-root documents and could not be deleted.`
          : `Finished deleting ${rootDocIds.length} documents.`
      );
    } catch (err) {
      this.msgService.error('Unable to delete documents(s).');

      console.error('Unable to delete documents(s)');
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing.emit(false);
    });
  }

  async forceDocumentStateChange(desiredState: DocumentState) {
    this.isProcessing.emit(true);

    try {
      await this.documentService.forceDocumentStateChange(this.selectedIds, desiredState);
    } catch (err) {
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing.emit(false);
    });
  }

  copyIds = async () => {
    await copyToClipboard(`"${this.selectedIds.join('","')}"`);

    this.msgService.info(`ids ${this.translate.instant('DOCUMENTLIST.copiedToClipboard')}`);
  };

  async toggleSnoozeSelected() {
    this.isProcessing.emit(true);
    let docIdsToSnooze: string[] = [];
    let docIdsToUnsnooze: string[] = [];
    this.selection.selected.forEach((d, index) => (d.snoozed_at ? docIdsToUnsnooze.push(d.id) : docIdsToSnooze.push(d.id)));

    try {
      await Promise.all(
        docIdsToSnooze.map(id =>
          this.documentService.snoozeDocument.mutate({ document_id: id, snooze_comment: null, snooze_tag_users: [] }).toPromise()
        )
      );
      await Promise.all(docIdsToUnsnooze.map(id => this.documentService.unsnoozeDocument.mutate({ document_id: id }).toPromise()));
      console.log('Finished toggle snoozing');
    } catch (err) {
      console.error('Unable to unsnooze documents(s)');
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing.emit(false);
    });
  }

  togglePopupTitle() {
    if (this.selectedIds.length > 1) {
      return `${this.translate.instant('DOCUMENTLIST.toggleSnoozeForAll', { amount: this.selectedIds.length })}`;
    } else {
      if (this.selection.selected[0].snoozed_at != undefined) {
        return `${this.translate.instant('DOCUMENTLIST.unsnooze')}?`;
      } else {
        return `${this.translate.instant('DOCUMENTLIST.snooze')}?`;
      }
    }
  }

  async unsnoozeSelected() {
    this.isProcessing.emit(true);

    try {
      await Promise.all(this.selectedIds.map(id => this.documentService.unsnoozeDocument.mutate({ document_id: id }).toPromise()));
      console.log('Finished unsnoozing');
    } catch (err) {
      console.error('Unable to unsnooze documents(s)');
      console.error(err);
    }

    setTimeout(() => {
      this.isProcessing.emit(false);
    });
  }

  toVerification() {
    this.isProcessing.emit(true);
    this.documentService.requestVerification
      .mutate({ document_ids: this.selectedIds })
      .pipe(
        take(1),
        finalize(() => {
          this.isProcessing.emit(false);
        })
      )
      .subscribe(() => {
        this.isProcessing.emit(false);
      });
  }
  clear() {
    this.selection.clear();
    this.cleared.emit();
  }
}
