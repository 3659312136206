import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import uniq from 'lodash/uniq';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { BehaviorSubject, Subject, combineLatest, of } from 'rxjs';
import { DocumentClassDeleteResponse } from 'src/app/graphql/data-graphql';
import { DocumentDataSource } from 'src/app/shared/services/document-data-source';
import { DocumentService } from 'src/app/shared/services/document.service';

import { Document, DocumentState } from '../../../graphql/frontend-data-graphql';

export interface DocumentWithLevel extends Document {
  level: number;
  children: DocumentWithLevel[];
}

@Component({
  selector: 'app-document-tree-viewer',
  styleUrls: ['./document-tree-viewer.component.less'],
  templateUrl: './document-tree-viewer.component.html',
  styles: []
})
export class DocumentTreeViewerComponent implements OnInit, OnDestroy {
  @Input()
  documents: SelectionModel<Document>;
  @Input()
  showRoot$ = new BehaviorSubject<boolean>(false);
  @Input()
  showDeleted$ = new BehaviorSubject<boolean>(false);

  isLoading = true;
  checklistSelection = new SelectionModel<Document>(true, [], undefined, (o1, o2) => o1.id == o2.id);

  DocumentState = DocumentState;
  private readonly destroy$ = new Subject();

  constructor(private documentService: DocumentService) {}

  stateColor: Record<any, string> = {
    [DocumentState.AwaitingBlindProcessingDecision]: 'rgb(174, 52, 235)',
    [DocumentState.AwaitingExport]: '#1890ff',
    [DocumentState.AwaitingExportVerification]: '#1890ff',
    [DocumentState.AwaitingPrediction]: 'rgb(174, 52, 235)',
    [DocumentState.AwaitingPreprocessing]: 'rgb(174, 52, 235)',
    [DocumentState.AwaitingSeparation]: 'rgb(174, 52, 235)',
    [DocumentState.AwaitingVerification]: '#1890ff',
    [DocumentState.Created]: 'rgb(174, 52, 235)',
    [DocumentState.Deleted]: 'lightgrey',
    [DocumentState.Error]: 'red',
    [DocumentState.ExportUnnecessary]: 'lightgrey',
    [DocumentState.Exported]: 'rgb(82, 196, 26)',
    [DocumentState.ExportedFailure]: 'rgb(82, 196, 26)',
    [DocumentState.Invalid]: 'red',
    [DocumentState.Preprocessed]: 'rgb(174, 52, 235)',
    [DocumentState.Separated]: 'rgb(174, 52, 235)',
    [DocumentState.Unprocessable]: 'red'
  };

  ngOnInit(): void {
    const source = new DocumentDataSource({
      filter: of({ root_document_id: { in: uniq(this.documents.selected.map(d => d.root_document_id)) } }),
      sorting: of([]),
      documentService: this.documentService,
      destroy: this.destroy$,
      pageSize: 999999,
      resolveParent: false,
      resolveRoot: false,
      withValue: true,
      withPredictionSource: true
    });
    combineLatest([source.data$, this.showDeleted$, this.showRoot$]).subscribe(([docs, showDeleted, showRoot]) => {
      let documents: DocumentWithLevel[] = docs.map(d => ({ ...d, level: 0, children: [] }));

      // sort nodes by state
      documents.sort((a, b) => a.state.localeCompare(b.state));

      let tree = this.createTree(documents);

      // right now, tree consists of all relevant root documents, might need to pick specific subdocuments instead
      if (!showRoot) tree = this.pickSelectedDocuments(tree);

      // note that this will never filter the roots themselves (i.e. a deleted root document will still be shown)
      if (!showDeleted) tree.forEach(t => this.filterTree(t, d => d.state != DocumentState.Deleted));

      this.dataSource.setData(tree);
      setTimeout(() => {
        // expand all top-level nodes by one level
        this.treeControl.dataNodes.filter(n => n.level == 0).forEach(t => this.treeControl.expand(t));
        this.isLoading = false;
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  filterTree(tree: DocumentWithLevel, predicate: (d: DocumentWithLevel) => boolean): void {
    tree.children = tree.children.filter(predicate);
    tree.children.forEach(c => this.filterTree(c, predicate));
  }

  createTree(items: DocumentWithLevel[]): DocumentWithLevel[] {
    const rootItems = items.filter(item => !item.parent_document_id) as any[] as DocumentWithLevel[];

    rootItems.forEach(root => {
      this.addChildItems(root, items);
    });

    return rootItems;
  }

  pickSelectedDocuments(rootItems: DocumentWithLevel[]): DocumentWithLevel[] {
    // find the documents that we are actually interested in, they might not be root documents
    const rootNodes: DocumentWithLevel[] = [];

    this.documents.selected.forEach(d => {
      const rootDocument = rootItems.find(r => r.id == d.root_document_id);
      if (!rootDocument) throw new Error(`Could not find root document ${d.root_document_id}`);

      const node = this.findChild(rootDocument, d.id);
      if (!node) throw new Error(`Could not find child ${d.id} in ${rootDocument.id}, might be deleted and therefore hidden?`);
      rootNodes.push(node);
    });

    return rootNodes;
  }

  private findChild(haystack: DocumentWithLevel, needle: string): DocumentWithLevel | null {
    if (haystack.id == needle) return haystack;

    for (const child of haystack.children) {
      const found = this.findChild(child, needle);
      if (found) return found;
    }

    return null;
  }

  private addChildItems(item: DocumentWithLevel, allItems: DocumentWithLevel[]): void {
    const children = allItems.filter(i => i.parent_document_id === item.id);

    children.forEach(child => {
      this.addChildItems(child, allItems);
    });

    item.children = children;
  }

  private transformer = (node: DocumentWithLevel, level: number): DocumentWithLevel => ({
    ...node,
    level
  });

  treeControl = new FlatTreeControl<DocumentWithLevel, string>(
    node => node.level,
    node => (node.children?.length ?? 0) > 0,
    { trackBy: (d: DocumentWithLevel) => d.id }
  );

  treeFlattener = new NzTreeFlattener<DocumentWithLevel, DocumentWithLevel, string>(
    this.transformer,
    node => node.level,
    node => (node.children?.length ?? 0) > 0,
    node => node.children
  );

  dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);

  showLeafIcon = false;

  hasChild = (_: number, node: DocumentWithLevel): boolean => (node.children?.length ?? 0) > 0;

  toggleLevel(node: DocumentWithLevel) {
    if (this.treeControl.isExpanded(node)) this.treeControl.collapseDescendants(node);
    else this.treeControl.expandDescendants(node);
  }

  leafItemSelectionToggle(node: DocumentWithLevel): void {
    this.checklistSelection.toggle(node);
  }
  checkTypeNode(node: DocumentWithLevel): node is DocumentWithLevel {
    return true;
  }
}
