<nz-drawer
  [nzClosable]="false"
  nzWidth="80%"
  [nzVisible]="visible"
  nzPlacement="right"
  (nzOnClose)="visibleChange.emit(false); visible = false">
  <ng-container *nzDrawerContent>
    <page-header class="mb-xl" [title]="treeDrawerTitle" [autoBreadcrumb]="false" [action]="action">
      <ng-template #treeDrawerTitle>
        <div class="list-tree anticon"></div>
        {{ 'DOCUMENT.TREE.title' | translate }}
        <nz-tag [nzColor]="'orange'">Beta</nz-tag>
      </ng-template>
    </page-header>
    <ng-template #action>
      <nz-switch
        [ngModel]="showDeleted$ | async"
        (ngModelChange)="showDeleted$.next($event)"
        [nzCheckedChildren]="deletedIconTemplate"
        [nzUnCheckedChildren]="deletedIconTemplate"
        style="font-size: 16px; margin-right: 8px"
        nz-tooltip
        [nzTooltipTitle]="'DOCUMENT.TREE.showDeleted' | translate"
        nzTooltipPlacement="bottom"></nz-switch>
      <ng-template #deletedIconTemplate><span nz-icon nzType="delete" style="font-size: 18px; padding-top: 1px"></span></ng-template>
      <nz-switch
        [ngModel]="showRoot$ | async"
        (ngModelChange)="showRoot$.next($event)"
        [nzCheckedChildren]="rootIconTemplate"
        [nzUnCheckedChildren]="rootIconTemplate"
        style="font-size: 16px"
        nz-tooltip
        [nzTooltipTitle]="'DOCUMENT.TREE.showRoot' | translate"
        nzTooltipPlacement="bottom"></nz-switch>
      <ng-template #rootIconTemplate><span nz-icon nzType="fork" style="font-size: 18px; padding-top: 1px"></span></ng-template>
    </ng-template>
    <app-document-tree-viewer
      class="mt-xl"
      *ngIf="visible"
      [documents]="documents"
      [showDeleted$]="showDeleted$"
      [showRoot$]="showRoot$"></app-document-tree-viewer>
  </ng-container>
</nz-drawer>
