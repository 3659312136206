<st
  #table
  [data]="$any(tableData$ | async)"
  [columns]="columns"
  [page]="page"
  [req]="req"
  [scroll]="{ y: scrollY || _scrollY }"
  virtualScroll
  nzShowSizeChanger
  [loading]="loading"
  [widthMode]="{ strictBehavior: 'truncate', type: 'strict' }"
  [size]="'small'"
  [virtualMinBufferPx]="200"
  [virtualMaxBufferPx]="500"
  [virtualItemSize]="virtualItemSize"
  [virtualForTrackBy]="virtualForTrackBy"
  (change)="processChange($event)"
  [expand]="expand">
  <ng-template st-row="document_id" let-item let-index="index">
    <ng-container>
      <a [routerLink]="[item.id]">{{ item | formatDocumentId }}</a>
      &nbsp;({{ item | formatDocumentName }})
    </ng-container>
  </ng-template>

  <!-- Veraltet -->
  <ng-template st-row="children" let-item let-index="index">
    <nz-row *ngFor="let document of item.children | filterUndeleted; trackBy: trackByFn">
      <nz-col [nzSpan]="3">📄</nz-col>
      <nz-col [nzSpan]="21">
        <a [routerLink]="[document.id]">{{ document | formatDocumentId }}</a>
        &nbsp;
        <span
          *ngIf="!this.auth.acl.can([PlatformRole.Developer])"
          [nz-tooltip]="document.document_class?.local_identifier ?? 'UNKNOWN'"
          [nzTooltipMouseEnterDelay]="1.25">
          (
          <i>{{ document.document_class?.display_name ?? 'UNKNOWN' }}</i>
          )
        </span>
        <span
          *ngIf="this.auth.acl.can([PlatformRole.Developer])"
          [nz-tooltip]="document.document_class?.display_name ?? 'UNKNOWN'"
          style="font-family: monospace">
          [{{ document.document_class?.local_identifier ?? 'UNKNOWN' }}]
        </span>
        <span [nz-tooltip]="this.auth.acl.can([PlatformRole.Developer]) ? '' : document.state">
          <i>{{ document.state | formatDocumentState : this.auth.acl.can([PlatformRole.Developer]) | translate }}</i>
        </span>
      </nz-col>
    </nz-row>
  </ng-template>

  <ng-template st-row="documentState" let-item let-index="index">
    <span [nz-tooltip]="this.auth.acl.can([PlatformRole.Developer]) ? '' : item.state">
      <i>{{ item.state | formatDocumentState : this.auth.acl.can([PlatformRole.Developer]) | translate }}</i>
    </span>
  </ng-template>

  <ng-template st-row="documentClass" let-item let-index="index">
    <span
      *ngIf="!this.auth.acl.can([PlatformRole.Developer])"
      [nz-tooltip]="item.document_class?.identifier"
      [nzTooltipMouseEnterDelay]="1.25">
      <i>{{ item.document_class?.display_name }}</i>
    </span>
    <span
      *ngIf="this.auth.acl.can([PlatformRole.Developer])"
      [nz-tooltip]="item.document_class?.display_name"
      style="font-family: monospace">
      {{ item.document_class?.identifier }}
    </span>
  </ng-template>

  <ng-template st-row="document_class_children" let-item let-index="index">
    <a *ngIf="item.children?.totalCount > 0; else no" [routerLink]="['../', item.identifier]">
      {{ item.children?.totalCount }} {{ 'COMMON.subclasses' | translate }}
    </a>
    <ng-template #no>✖</ng-template>
  </ng-template>

  <ng-template st-row="identifier" let-item let-index="index">
    <span style="font-family: monospace">{{ item.identifier }}</span>
  </ng-template>

  <ng-template st-row="createdDate" let-item let-index="index">
    <span [nz-tooltip]="item.created | date : ' dd.MM.yy HH:mm' : 'de-De'">{{ item.created | formatTimeDistance }}</span>
  </ng-template>

  <ng-template st-row="eventTime" let-item let-index="index">
    <span [nz-tooltip]="item.time | date : 'dd.MM.yy HH:mm:ss.SSS' : 'de-De'">{{ item.time | formatTimeDistance }}</span>
  </ng-template>

  <ng-template st-row="eventSource" let-item let-index="index">
    <span [nz-tooltip]="item.source">{{ item.source | formatCeSource }}</span>
  </ng-template>

  <ng-template st-row="eventType" let-item let-index="index">
    <span [nz-tooltip]="item.type">{{ item.type | formatCeType }}</span>
  </ng-template>

  <ng-template st-row="eventInfo" let-item let-index="index">
    <ng-container *ngIf="item.prevState">
      {{ 'DOCUMENT.INFO.changedFrom' | translate }}
      <span class="prev-state">{{ item.prevState }}</span>
      {{ 'DOCUMENT.INFO.to' | translate }}
      <span class="new-state">{{ item.newState }}</span>
    </ng-container>
    <ng-container *ngIf="item.fromState">
      <span class="request">{{ 'DOCUMENT.INFO.request' | translate }}</span>
      {{ 'DOCUMENT.INFO.changedFrom' | translate }}
      <span class="prev-state">{{ item.fromState }}</span>
      {{ 'DOCUMENT.INFO.to' | translate }}
      <span class="new-state">{{ item.desiredState }}</span>
    </ng-container>
    <ng-container *ngIf="item.predictionId">
      <!--{{ 'DOCUMENT.INFO.predictionId' | translate }}:
      {{ item.predictionId }}
      <br />-->
      {{ 'DOCUMENT.INFO.confirmedness' | translate }}:
      <span class="number">{{ item.confirmedness.toFixed(2) }}</span>
      <br />
      {{ 'DOCUMENT.INFO.maxCandidateScore' | translate }}:
      <span class="number">{{ item.maxCandidateScore.toFixed(2) }}</span>
    </ng-container>
    <ng-container *ngIf="item.newSubdocuments && item.newSubdocuments.length > 0">
      {{ 'DOCUMENT.INFO.newSubDocuments' | translate }}
      <ul>
        <li *ngFor="let sub of item.newSubdocuments">
          <span class="docid">
            <a target="_blank" [routerLink]="['/documents', sub.id]">{{ sub.id }}</a>
          </span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="item.deletedSubdocuments && item.deletedSubdocuments.length > 0">
      {{ 'DOCUMENT.INFO.deletedSubdocuments' | translate }}
      <ul>
        <li *ngFor="let sub of item.deletedSubdocuments">
          <span class="docid">
            <a target="_blank" [routerLink]="['/documents', sub.id]">{{ sub.id }}</a>
          </span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="item.unchangedSubdocuments && item.unchangedSubdocuments.length > 0">
      {{ 'DOCUMENT.INFO.unchangedSubdocuments' | translate }}
      <ul>
        <li *ngFor="let sub of item.unchangedSubdocuments">
          <span class="docid">
            <a target="_blank" [routerLink]="['/documents', sub.id]">{{ sub.id }}</a>
          </span>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="item.reason">
      <span nz-tooltip [nzTooltipTitle]="item.reason">{{ item.reason }}</span>
    </ng-container>

    <ng-container *ngIf="item.retentionEnabled !== undefined">
      <span *ngIf="item.retentionEnabled">{{ 'DOCUMENT.INFO.retentionEnabled' | translate }}</span>
      <span *ngIf="!item.retentionEnabled">{{ 'DOCUMENT.INFO.retentionDisabled' | translate }}</span>
      <span *ngIf="item.durationAfterCreation">
        {{ 'DOCUMENT.INFO.deletion' | translate }} {{ item.durationAfterCreation }}s {{ 'DOCUMENT.INFO.durationAfterCreation' | translate }}
      </span>
      <span *ngIf="item.durationAfterFinished">
        {{ 'DOCUMENT.INFO.deletion' | translate }} {{ item.durationAfterFinished }}s {{ 'DOCUMENT.INFO.durationAfterFinished' | translate }}
      </span>
    </ng-container>

    <ng-container *ngIf="item.userId">
      <br />
      {{ 'DOCUMENT.INFO.by' | translate }}
      <span class="user-id">{{ item.userId }}</span>
    </ng-container>
  </ng-template>

  <ng-template st-row="blocked" let-item let-index="index">
    <ng-container *ngIf="documentService.isBlockedByOthers(item); else notBlockedByMe">
      <i
        nz-tooltip="Von {{ item.blocked_by?.name }} bis {{ item.locked_until | date : 'HH:mm dd.MM.yy' : 'de-De' }} blockiert."
        class="icon"
        style="color: red; margin-right: 4px; font-size: 1.4em"
        nz-icon
        nzType="lock"></i>
    </ng-container>
    <ng-template #notBlockedByMe>
      <ng-container *ngIf="documentService.isBlockedByMe(item)">
        <i
          nz-tooltip="Von dir bis {{ item.locked_until | date : 'HH:mm dd.MM.yy' : 'de-De' }} blockiert"
          class="icon"
          style="color: green; margin-right: 4px; font-size: 1.4em"
          nz-icon
          nzType="lock"></i>
      </ng-container>
    </ng-template>
  </ng-template>

  <ng-template st-row="snoozed" let-item let-index="index">
    <ng-container *ngIf="item.snoozed_at">
      <div class="snooze"></div>
    </ng-container>
  </ng-template>

  <!-- USERS VIEW -->
  <ng-template st-row="userName" let-item>
    <app-user-avatar [userName]="item.name ?? ''" [userMail]="item.email" [showNameAndMail]="true"></app-user-avatar>
  </ng-template>

  <ng-template st-row="orgaTag" let-item let-index="index">
    <span
      #orgaTag
      nz-popover
      nzPopoverTrigger="hover"
      [nzPopoverContent]="orgaPopover"
      [nzPopoverTitle]="'ADMIN.USERS.organizations' | translate"
      [nzPopoverMouseEnterDelay]="0.5"
      [nzPopoverOverlayStyle]="{ 'max-width': '500px' }">
      <nz-tag *ngFor="let orga of item.organization_memberships" [nzColor]="'blue'">{{ orga.organization?.name }}</nz-tag>
    </span>
    <ng-template #orgaPopover>
      <nz-tag class="role-tag" *ngFor="let orga of item.organization_memberships" [nzColor]="'blue'">
        {{ orga.organization?.name }}
        <span class="roles-sub" *ngIf="orga.roles.length > 0">({{ orga.roles.join(', ') }})</span>
      </nz-tag>
    </ng-template>
  </ng-template>

  <ng-template st-row="platformRoleTag" let-item>
    <nz-tag *ngFor="let role of item.platform_roles">{{ role }}</nz-tag>
  </ng-template>

  <ng-template st-row="orgaRoleTag" let-item>
    <nz-tag *ngFor="let role of item.organization_memberships[0].roles">{{ role }}</nz-tag>
  </ng-template>

  <ng-template st-row="loginDisableTitle" type="title">
    <span
      #loginDisableTitle
      nzTooltipPlacement="topLeft"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.75"
      [nzTooltipTitle]="'ADMIN.USERS.loginEnabled' | translate">
      {{ 'ADMIN.USERS.enabled' | translate }}
    </span>
  </ng-template>

  <!-- <ng-template st-row="parameter" let-item let-index="index">
    <ngx-json-viewer [json]="JSON.parse(item.parameter)" [expanded]="false"></ngx-json-viewer>
  </ng-template>

  <ng-template st-row="status" let-item let-index="index">
    <b [ngStyle]="{ color: item.status === 'error' ? 'red' : 'green' }">{{ item.status }}</b></ng-template
  >

  <ng-template st-row="log" let-item let-index="index">
    <div class="overflow">{{ item.log }}</div>
  </ng-template>

  <ng-template st-row="error" let-item let-index="index">
    <div class="overflow">{{ item.error }}</div>
  </ng-template>

  <ng-template st-row="exported_at" let-item let-index="index">
    <span *ngIf="item.exported_at; else exportWarning" [nz-tooltip]="item.exported_at | date: 'HH:mm dd.MM.yy':'de-De'"
      >{{ item.exported_at | formatTimeDistance }}</span
    >
    <ng-template #exportWarning>
      <span *ngIf="item.export_failed_at; else noExport">
        <span [nz-tooltip]="'Letzter Versuch' + item.export_failed_at | date: 'HH:mm dd.MM.yy':'de-De'" style="color: #ffa500">ⓘ</span>
        Export Versuche {{item.export_retries}}
      </span>
      <ng-template #noExport>
        <span style="color: #ffa500">ⓘ</span>
        Kein Export
      </ng-template>
    </ng-template>
  </ng-template>

  <ng-template st-row="level" let-item let-index="index">
    <b [ngStyle]="{ color: levelColors[item.level] }">{{ item.level }}</b>
  </ng-template>

  <ng-template st-row="tags" let-item let-index="index">
    <nz-tag *ngFor="let tag of item.tags" [nzColor]="'blue'">{{ tag }}</nz-tag>
  </ng-template>

  <ng-template st-row="letter-status" let-item let-index="index">
    <div class="letter-status-cell">
      <span><ng-container *ngTemplateOutlet="Status; context: { status: item.import_status, text: 'Import: ' }"></ng-container></span>
      <span
        ><ng-container *ngTemplateOutlet="Status; context: { status: item.classification_status, text: 'Brief: ' }"></ng-container
      ></span>
      <span
        ><ng-container *ngTemplateOutlet="Status; context: { status: item.document_classification_status, text: 'Seiten: ' }"></ng-container
      ></span>
      <span
        ><ng-container *ngTemplateOutlet="Status; context: { status: item.extraction_status, text: 'Extraktion: ' }"></ng-container
      ></span>
      <span><ng-container *ngTemplateOutlet="Status; context: { status: item.export_status, text: 'Export: ' }"></ng-container></span>
    </div>
  </ng-template>

  <ng-template #Status let-status="status" let-text="text">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="'NorosProcessing'">{{ text }}<i nz-icon nzType="cloud-sync" nzTheme="outline"></i></ng-container>
      <ng-container *ngSwitchCase="'NorosError'">{{ text }}<i class="error" nz-icon nzType="close" nzTheme="outline"></i></ng-container>
      <ng-container *ngSwitchCase="'Pending'">{{ text }}<i class="primary" nz-icon nzType="edit" nzTheme="outline"></i></ng-container>
      <ng-container *ngSwitchCase="'Finished'">{{ text }}<i class="success" nz-icon nzType="check" nzTheme="outline"></i></ng-container>
      <ng-container *ngSwitchCase="'Skipped'"></ng-container>
      <ng-container *ngSwitchDefault>{{ text }}<i nz-icon nzType="question" nzTheme="outline"></i></ng-container>
    </ng-container>
  </ng-template>

  <ng-template st-row="documents" let-item let-index="index">
    <nz-row *ngFor="let doc of item.documents; trackBy: trackByFn">
      <nz-col [nzSpan]="3"> 📄 </nz-col>
      <nz-col [nzSpan]="21"> {{ doc.document_type?.name }} </nz-col>
    </nz-row>
  </ng-template>



  <ng-template st-row="date" type="title" let-c>
    <div nzCustomFilter>
      {{ c.title.text }}
      <nz-filter-trigger [(nzVisible)]="dateRateFilterVisible" [nzDropdownMenu]="menu">
        <i nz-icon nzType="filter" nzTheme="fill"></i>
      </nz-filter-trigger>
    </div>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div nz-menu style="width: 300px; height: 100px; padding: 10px">
        <nz-row>
          <nz-col [nzSpan]="24">
            <nz-range-picker [nzShowTime]="true" [(ngModel)]="dateList"></nz-range-picker>
          </nz-col>
        </nz-row>
        <nz-row style="margin-top: 10px">
          <nz-col [nzSpan]="24">
            <button style="position: absolute; right: 5px" nz-button nzType="primary" (click)="applyDateRangeFilter(c.index, dateList)">
              Anwenden
            </button>
          </nz-col>
        </nz-row>
      </div>
    </nz-dropdown-menu>
  </ng-template> -->
</st>
<!-- <div>Index: {{ scrollIndex }}</div> -->
<ng-template #noresult>
  <nz-result>{{ 'COMMON.noEntries' | translate }}</nz-result>
</ng-template>
