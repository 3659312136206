import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { Layout, SettingsService, User as DelonUser } from '@delon/theme';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { MosaicAppSettings } from 'src/app/core/app.state';
import { PlatformRole, User } from 'src/app/graphql/data-graphql';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'header-user',
  template: `
    <div
      *ngIf="authService.currentUser"
      class="alain-default__nav-item d-flex align-items-center"
      nz-dropdown
      nzPlacement="bottomRight"
      [nzDropdownMenu]="userMenu">
      <app-user-avatar
        [userName]="(authService.currentUser.first_name ?? '') + ' ' + (authService.currentUser.last_name ?? '')"
        [userMail]="authService.currentUser.email"
        [showWithHeaderColor]="true"></app-user-avatar>
      <nz-dropdown-menu #userMenu="nzDropdownMenu">
        <div nz-menu class="width-sl">
          <div class="avatar-padding">
            <app-user-avatar
              [userName]="(authService.currentUser.first_name ?? '') + ' ' + (authService.currentUser.last_name ?? '')"
              [userMail]="authService.currentUser.email"
              [showNameAndMail]="true"></app-user-avatar>
          </div>
          <li nz-menu-divider></li>
          <div nz-menu-item (click)="openAccountSettings()">
            <i nz-icon nzType="setting" class="mr-sm"></i>
            {{ 'BASIC.USER.editProfile' | translate }}
          </div>
          <div nz-menu-item (click)="openPasswordSettings()">
            <i nz-icon nzType="lock" class="mr-sm"></i>
            {{ 'BASIC.USER.changePassword' | translate }}
          </div>
          <li nz-menu-divider></li>
          <div nz-menu-item>
            <header-fullscreen></header-fullscreen>
          </div>
          <div nz-menu-item>
            <header-clear-storage></header-clear-storage>
          </div>
          <li nz-menu-divider></li>
          <div nz-menu-item (click)="$event.stopImmediatePropagation()">
            <nz-radio-group [ngModel]="translate.currentLang" (ngModelChange)="changeLanguage($event)" (click)="$event.stopPropagation()">
              <label *ngFor="let lang of availableLangues$ | async" nz-radio-button [nzValue]="lang">
                {{ 'LANGUAGE.' + lang | translate }}
              </label>
            </nz-radio-group>
            <!-- <nz-select  (click)="$event.stopPropagation()">
            <nz-option *ngFor="let lang of translate.getLangs()" [nzValue]="lang" [nzLabel]="lang">{{ lang }}</nz-option>
          </nz-select> -->
          </div>
          <li nz-menu-divider></li>

          <div nz-menu-item (click)="authService.logout()">
            <i nz-icon nzType="logout" class="mr-sm"></i>
            {{ 'BASIC.USER.logout' | translate }}
          </div>
        </div>
      </nz-dropdown-menu>
    </div>
  `,
  styles: ['.avatar-padding {padding: 5px 12px;}'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderUserComponent {
  get user(): DelonUser {
    return this.settings.user;
  }

  selectedLang = this.translate.defaultLang;
  availableLangues$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(
    private settings: SettingsService<Layout, DelonUser, MosaicAppSettings>,
    private router: Router,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    public authService: AuthService,
    private usersService: UserService,
    public translate: TranslateService
  ) {
    let langs = translate.getLangs();
    let filteredLangs = langs.filter(l => authService.acl.can([PlatformRole.Developer]) || l != 'none');

    this.availableLangues$.next(filteredLangs);
  }

  logout(): void {
    this.authService.logout();
  }

  openAccountSettings() {
    this.usersService.currentUser().subscribe(user => {
      this.usersService.openEditModal(user as any);
    });
  }

  openPasswordSettings() {
    this.usersService.currentUser().subscribe(user => {
      this.usersService.openPasswordModal(user as any);
    });
  }

  changeLanguage($event: string) {
    this.translate.use($event);
    localStorage.setItem('selectedLanguage', $event);
  }
}
