/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
// Version upgrade
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { Observable, take } from 'rxjs';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ServiceWorkerModule } from '@angular/service-worker';

// #region default language
// Reference: https://ng-alain.com/docs/i18n
// TODO: Fix language settings
import { default as ngLang } from '@angular/common/locales/en';
import { DELON_LOCALE, en_US as delonLang } from '@delon/theme';
import de from 'date-fns/locale/de';
import { de_DE as zorroLang, NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';

const LANG = {
  abbr: 'de',
  ng: ngLang,
  zorro: zorroLang,
  date: de,
  delon: delonLang
};
// register angular
import { LocationStrategy, LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
registerLocaleData(LANG.ng, LANG.abbr);
const LANG_PROVIDES = [
  { provide: LOCALE_ID, useValue: LANG.abbr },
  { provide: NZ_I18N, useValue: LANG.zorro },
  { provide: NZ_DATE_LOCALE, useValue: LANG.date },
  { provide: DELON_LOCALE, useValue: LANG.delon }
];
// #endregion

// #region Http Interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DefaultInterceptor } from '@core';
import { SimpleInterceptor } from '@delon/auth';
const INTERCEPTOR_PROVIDES: any[] = [{ provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true }];
// #endregion

// #region global third module
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(translateService: TranslateService, injector: Injector): () => Promise<any> {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const availableLangs = ['en', 'de', 'none'];
        translateService.addLangs(availableLangs);
        translateService.setDefaultLang('none');

        let langToSet: string | null = null;
        if (localStorage.getItem('selectedLanguage')) {
          langToSet = localStorage.getItem('selectedLanguage') as string;
        }

        if (!langToSet) {
          const browserLang = translateService.getBrowserLang();
          if (browserLang && availableLangs.includes(browserLang)) langToSet = browserLang;
        }

        if (!langToSet || !availableLangs.includes(langToSet)) langToSet = 'de';

        translateService.use(langToSet).subscribe(
          () => {
            console.info(`Successfully initialized '${langToSet}' language.`);
            resolve(true);
          },
          err => {
            console.error(`Problem with '${langToSet}' language initialization.`);
          }
        );
      });
    });
}

const GLOBAL_THIRD_MODULES: any[] = [];
// #endregion

const APPINIT_PROVIDES = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInitializerFactory,
    deps: [TranslateService, Injector],
    multi: true
  },
  {
    provide: RouteReuseStrategy,
    useClass: ReuseTabStrategy,
    deps: [ReuseTabService]
  }
];
// #endregion

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GlobalConfigModule } from './global-config.module';
import { LayoutModule } from './layout/layout.module';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { RouteReuseStrategy } from '@angular/router';
import { ReuseTabService, ReuseTabStrategy } from '@delon/abc/reuse-tab';
import { FreshdeskService } from './freshdesk.service';
import { PathPreserveQueryLocationStrategy } from './preserve-query-params';
import { environment } from '@env/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GlobalConfigModule.forRoot(),
    CoreModule,
    SharedModule,
    LayoutModule,
    RoutesModule,
    NzMessageModule,
    NzNotificationModule,
    ScrollingModule,
    ...GLOBAL_THIRD_MODULES,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ...LANG_PROVIDES,
    ...INTERCEPTOR_PROVIDES,
    ...APPINIT_PROVIDES,
    { provide: LocationStrategy, useClass: PathPreserveQueryLocationStrategy },
    { provide: FreshdeskService, useClass: FreshdeskService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
