import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, startWith } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Pipe({
  name: 'addToken'
})
export class AddTokenPipe implements PipeTransform {
  constructor(private auth: AuthService) {}

  transform(str: string, ...args: unknown[]): Observable<string> {
    return this.auth.token$.pipe(
      map(token => `${str}?token=${token}`),
      startWith('')
    );
  }
}
