import { Component, Input, OnInit, ElementRef, Host, Optional } from '@angular/core';
import { NzAvatarGroupComponent } from 'ng-zorro-antd/avatar';
import { NzSizeLDSType } from 'ng-zorro-antd/core/types';

/**
 * Component representing a user avatar with initials and a colored background based on the user's email.
 * On default it shows the avatar without name and email on the right.
 *
 * @example
 * <app-user-avatar
 *   [userMail]="user.email"
 *   [userName]="user.name"
 * ></app-user-avatar>
 */
@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.less']
})
export class UserAvatarComponent implements OnInit {
  /** Only needed inside an avatar group. Determine whether the avatar is the first one in the loop. */
  @Input()
  index: number;

  /** Mail of user to show. Will be used for picking an avatar color. */
  @Input()
  userMail: string;

  /** Provides text content to be displayed within the avatar. If no text is provided,
   * the component will automatically generate initials based on the user's name or other available information. */
  @Input()
  isInGroup: boolean = false;

  /** User's name. The first letter of every word is used for the avatar. */
  @Input()
  userName: string;

  /** Indicates if avatar is shown with name and email on the right. If false, avatar size is smaller. Default false. */
  @Input()
  showNameAndMail = false;

  /** Determines the size of the avatar component. It accepts one of the following values:
   * - 'small'
   * - 'default'
   * - 'large' */
  @Input()
  nzSize: NzSizeLDSType = 'default';

  /** Indicates if avatar color is white with header color as font. Default false.*/
  @Input()
  showWithHeaderColor = false;

  /** Indicates if avatar is inside an avatar group. */
  isInsideAvatarGroup: boolean;

  /** User's initials, which are visible on the avatar. */
  initials: string;

  /** Styling of the avatar. Includes background color and text color in HSL format.*/
  colorStyle: { backgroundColor: string; color: string };

  constructor(@Host() @Optional() private avatarGroup: NzAvatarGroupComponent) {}

  ngOnInit() {
    /* Bug: Mismatched avatars in user list -> https://app.clickup.com/t/861n7tvd4 */
    this.initials = this.getInitials();
    this.colorStyle = this.getColorStyle();
    this.isInsideAvatarGroup = !!this.avatarGroup;
  }

  /**
   * Calculates the background color and text color based on the user's email.
   * If `showWithHeaderColor` is true, the background color is white and the text color is the header color (#1890ff).
   * Otherwise, it generates colors based on the user's email using a pseudo-random algorithm.
   * The generated colors are in HSL format (Hue, Saturation, Lightness).
   *
   * @returns {{ backgroundColor: string; color: string }} Containing the background color and text color in HSL format.
   */
  private getColorStyle(): { backgroundColor: string; color: string } {
    // Generates hash value based on the characters of an email string
    const customHash = (email: string): number => {
      let hash = 0;
      for (let i = 0; i < email.length; i++) {
        const char = email.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash;
      }
      return Math.abs(hash) % 360;
    };

    // Pseudo-random function based on email hash
    const pseudoRandom = (hash: number) => {
      const a = 0.618033988749895; // Golden ratio (better distribution)
      const shift = 17; // Number of bit shift for integer representation

      let x = hash;
      x = (x ^ (x >> shift)) * a;
      x = (x - Math.floor(x)) * 2 - 1;

      return x;
    };

    // Adjusts a numeric value (brightness or saturation) based on an adjustment factor.
    const adjustBrightnessAndSaturation = (value: number, adjustValue: number): number => {
      return Math.max(0, Math.min(100, value + adjustValue));
    };

    const hash = customHash(this.userMail);
    const hue = hash;
    const saturation = 70; // Initial saturation
    const lightness = 80; // Initial brightness
    const range = 5;
    const randomSaturationAdjustment = pseudoRandom(hash) * range;
    const randomLightnessAdjustment = pseudoRandom(hash) * range;

    const adjustedSaturation = adjustBrightnessAndSaturation(saturation, randomSaturationAdjustment);
    const adjustedLightness = adjustBrightnessAndSaturation(lightness, randomLightnessAdjustment);

    const backgroundColor = this.showWithHeaderColor ? 'white' : `hsl(${hue}, ${adjustedSaturation}%, ${adjustedLightness}%)`;
    const textColor = this.showWithHeaderColor ? '#1890ff' : `hsl(${hue}, ${adjustedSaturation}%, ${adjustedLightness - 60}%)`; // Text color is darker

    return { backgroundColor, color: textColor };
  }

  /**
   * Generates initials for avatar based on the user's name.
   * If user name is available, it extracts the first letter of each word and converts them to uppercase.
   * If user name is not available (e.g. internal role), it uses the first letter of the email address as initials.
   *
   * @returns {string} The user initials in uppercase, e.g., "FVT" for "Fleck von Traktor".
   */
  private getInitials(): string {
    const initials = (this.userName ?? this.userMail).trim() || '';
    return initials
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');
  }
}
