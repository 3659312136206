import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ACLService } from '@delon/acl';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { App, Layout, SettingsService, User, _HttpClient } from '@delon/theme';
import { environment } from '@env/environment';
import jwt_decode from 'jwt-decode';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, skip, startWith, switchMap, take, tap } from 'rxjs/operators';
import { MosaicAppSettings } from 'src/app/core/app.state';
import { ApolloOptionsService } from 'src/app/graphql/apollo-options.service';
import { Organization, OrganizationRole, PlatformRole } from 'src/app/graphql/data-graphql';
import { Scope } from 'src/app/graphql/frontend-data-graphql';
import { createApollo } from 'src/app/graphql/graphql.module';

export type Lookup<T extends { identifier: string; name: string }> = Pick<T, 'identifier' | 'name'>;

enum TokenKind {
  // Short-lived, grants access to stuff
  AccessToken = 'AccessToken',
  // Long-lived, can be used once to obtain a new access token and to switch organizations
  RefreshToken = 'RefreshToken'
}

export interface ITokenResponse {
  access_token: string;
  refresh_token: string;
}

export interface ActiveOrganization {
  // Identifier of the organization the user is currently logged in for
  organization_id: string;
  // Name of the organization the user is currently logged in for
  organization_name: string;
  // Permissions that the user has in the current organization
  roles: OrganizationRole[];
}

export interface JwtToken {
  // ID of the token (UUIDv4)
  id: string;
  // The id of the refresh token that was used to generate this token. The only token that doesn't have this set should be the first refresh token that is obtained after a fresh login
  source_token_id?: string;
  // What this token can be used for
  kind: TokenKind;
  // email address is the user's id and username
  email: string;
  // User's first name, if set
  first_name?: string;
  // User's last name, if set
  last_name?: string;
  // The currently active organization
  active_organization?: ActiveOrganization;
  // Global permissions
  platform_roles: PlatformRole[];
  // Expiry date of the token (depends on the type of token)
  expires_at: Date;
  // Issue date of the token
  issued_at: Date;
  // JWT claims
  // Expiration time (as UTC timestamp)
  exp: string;
  // Issued at (as UTC timestamp)
  iat: string;
  // Issuer, will usually be "lector.ai GmbH"
  iss: string;
  // Not Before (as UTC timestamp), will be set to `iat`
  nbf: string;
  // Subject (whom token refers to), will contain the user's email address
  sub: string;
}

export interface MosaicAuthInfo {
  token: string;
  refresh_token: string;
  user: JwtToken;
  expired: number;
}

export interface CategoryMenuEntry {
  label: string;
  icon: string;
  regex?: string; // See https://www.mongodb.com/docs/manual/reference/operator/query/regex/

  platform_roles?: PlatformRole[];
  organization_roles?: OrganizationRole[];
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public refreshToking = false;
  private currentTokenPair$: BehaviorSubject<ITokenResponse | null> = new BehaviorSubject<ITokenResponse | null>(null);

  constructor(
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private settingService: SettingsService<Layout, User, MosaicAppSettings>,
    private injector: Injector,
    public acl: ACLService,
    private msg: NzMessageService,
    private router: Router,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private notification: NzNotificationService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      const organizationP = params['organization'];
      const scopeP = params['scope'];

      if (scopeP && scopeP != '') {
        let scope: Scope = Scope[scopeP as keyof typeof Scope];
        if (scope != this.scope$.value && scope) {
          this.scope$.next(scope);
        }
      }
      if (organizationP && organizationP != '') {
        if (organizationP != this.organization$.value?.identifier) {
          this.switchOrganization({ identifier: organizationP });
        }
      }
    });

    this.scope$.pipe(skip(1)).subscribe(scope => {
      this.settingService.setApp({
        ...this.settingService.app,
        scope: scope
      });
    });

    this.availableScopes$ = this.user$.pipe(
      map(user => {
        if (this.acl.can([PlatformRole.Developer, PlatformRole.Internal]))
          return [Scope.Production, Scope.Testing, Scope.Training, Scope.Development, Scope.HealthCheck];

        if (this.acl.can([OrganizationRole.Verifier, OrganizationRole.OrganizationAdmin, PlatformRole.PlatformAdmin]))
          return [Scope.Production, Scope.Testing, Scope.Training];

        if (this.acl.can(OrganizationRole.Labeler)) return [Scope.Training, Scope.Testing];

        this.msg.error('Achtung: Sie haben keinen Zugriff auf bestehende Dokumente.');
        return [];
      })
    );

    this.token$ = this.tokenService.change().pipe(
      startWith(this.tokenService.get()),
      map(c => c?.token),
      filter(t => t != undefined),
      shareReplay(1)
    );

    this.tokenService.refresh.pipe(switchMap(() => this.refreshTokenRequest())).subscribe(() => {});

    this.initUserAndOrganisation();
  }
  get user() {
    return this.user$.value;
  }

  user$ = new BehaviorSubject<JwtToken | null>(null);
  organization$ = new BehaviorSubject<Organization | null>(null);

  scope$ = new BehaviorSubject<Scope | null>(null);
  availableScopes$: Observable<Scope[]> = of([]);

  get currentUser() {
    return this.tokenService.get<MosaicAuthInfo>()?.user;
  }

  get token() {
    return this.tokenService.get()?.token;
  }

  token$: Observable<any>;

  initUserAndOrganisation() {
    this.user$.next(this.currentUser);
    if (!this.currentUser) {
      this.logout();
      return;
    }
    this.organization$.next(<any>{
      name: this.currentUser.active_organization?.organization_name ?? 'Unknown',
      identifier: this.currentUser.active_organization?.organization_id
    });

    const user = this.user;

    if (user) {
      this.settingService.setUser<JwtToken>(user);
      console.log(`Setting roles: ${[...(user?.platform_roles ?? []), ...(user?.active_organization?.roles ?? [])]}`);
      this.acl.setRole([...(user?.platform_roles ?? []), ...(user?.active_organization?.roles ?? [])]);
    }

    this.availableScopes$.pipe(take(1)).subscribe(availableScopes => {
      const scope = this.settingService.app.scope;
      // If no scope is stored in the settings (cache), let's set a default scope
      if (!scope || !availableScopes.includes(scope)) {
        this.scope$.next(availableScopes[0]);
      } else if (scope && this.scope$.value != scope) {
        // If a scope is set in the setting (cache) and not set yet in this service, set it
        this.scope$.next(scope);
      }
    });
  }

  logout(): void {
    console.log('Logout, clearing tokens and navigating to login page');
    this.tokenService.clear();
    this.organization$.next(null);
    this.scope$.next(null);
    this.navigateToLogin();
  }

  login(email: string, password: string) {
    return this.http.post(`${environment.api.baseUrl}auth/login`, { email: email, password: password }, {}).pipe(
      map(res => res as ITokenResponse),
      switchMap((res: ITokenResponse) => {
        const decodedToken = jwt_decode<JwtToken>(res.access_token);
        this.tokenService.set({
          token: res.access_token,
          refresh_token: res.refresh_token,
          user: decodedToken,
          expired: new Date(decodedToken.expires_at).getTime()
        });
        const createApolloAndNavigate = async () => {
          const apolloService = this.injector.get(ApolloOptionsService);
          await createApollo(apolloService)();
          this.initUserAndOrganisation();
          this.router.navigateByUrl('/');
        };
        return from(createApolloAndNavigate());
      })
    );
  }

  async getSecureBase64AsString(url: string) {
    const blobToBase64 = (blob?: Blob) =>
      new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob ?? new Blob());
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
      });

    const blob = await this.http
      .get(`/storage/${url}`, {
        headers: {
          authorization: `Bearer ${this.token}`
        },
        responseType: 'blob'
      })
      .toPromise();
    return blobToBase64(blob);
  }

  async getSecureBase64AsSafeUrl(url: string) {
    const blob = await this.http
      .get(`/storage/${url}`, {
        headers: {
          authorization: `Bearer ${this.token}`
        },
        responseType: 'blob'
      })
      .toPromise();
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob ?? new Blob()));
  }

  private checkIfTokenIsNotExpired(params?: any): boolean {
    const token = this.tokenService.get<MosaicAuthInfo>();
    if (!token) return false;

    if (params && params['organization_id']) {
      const current_org = token?.user?.active_organization?.organization_id;
      if (current_org != params['organization_id']) return false;
    }

    const expired_in_unix_time = token?.expired;
    if (expired_in_unix_time && new Date(expired_in_unix_time) > new Date()) return true;
    return false;
  }

  public refreshTokenRequest(params?: any): Observable<ITokenResponse> {
    // if needed, try to obtain a new token through the refresh token mechanism.
    // The returned observable will contain a valid refresh token.
    // If that can't be obtained the returned observable will never fire, and the user is redirected to the login page.

    // Check if the refresh of the token is still in progress
    // or if in the meantime, the token has been already refreshed
    if (this.refreshToking || (this.checkIfTokenIsNotExpired(params) && this.currentTokenPair$.value)) {
      // in this case, either wait for the new token or return the current, valid token
      return this.currentTokenPair$.pipe(
        filter(v => !!v),
        map(v => v!),
        take(1)
      );
    }

    // Look for local refresh token
    const model = this.tokenService.get();
    if (!model || !model['refresh_token']) {
      return throwError('Cannot refresh token because we have no refresh token in storage.');
    }

    // Start the refreshing logic
    this.refreshToking = true;
    this.currentTokenPair$.next(null);

    const httpService = this.injector.get(_HttpClient);

    return httpService.post(`/auth/refresh`, { refresh_token: model['refresh_token'] }, params).pipe(
      tap((res: ITokenResponse) => {
        const decodedAccessToken = jwt_decode<JwtToken>(res.access_token);
        this.tokenService.set({
          token: res.access_token,
          refresh_token: res.refresh_token,
          user: decodedAccessToken,
          expired: new Date(decodedAccessToken.expires_at).getTime()
        });
        this.currentTokenPair$.next(res);
        this.refreshToking = false;
      }),
      catchError((err: HttpErrorResponse) => {
        this.refreshToking = false;

        if (err.status == 401) {
          // sometimes, we observe concurrent calls to refresh, which then fail because the refresh token has already been used.
          // we fix this by only logging out the user if the token actually seems invalid.
          if (!this.checkIfTokenIsNotExpired(params)) {
            // this will never fire the observable, but that's ok because we are navigating away anyway
            this.toLogin();
            return of();
          }
        }

        return throwError(() => err);
      })
    );
  }

  public toLogin(): void {
    this.refreshToking = false;
    this.notification.error('Fehler beim Aktualisieren der Login-Referenzen.', '');
    this.navigateToLogin();
  }

  navigateToLogin() {
    this.router.navigate([this.tokenService.login_url ?? '/passport/login'], {
      queryParams: {}
    });
  }

  switchOrganization(o: Partial<Organization>) {
    if (o.identifier) {
      this.organization$.next(null);

      this.refreshTokenRequest({
        organization_id: o.identifier
      }).subscribe(() => {
        this.initUserAndOrganisation();
      });
    }
  }
}
