<div *ngIf="selection.selected.length ?? 0 > 0" class="bar">
  <button nz-button (click)="clear()">
    <i nz-icon nzType="close" nzTheme="outline"></i>
    {{ 'DOCUMENTLIST.clearSelection' | translate }} ({{ selection.selected.length }})
  </button>

  <ng-container *aclIf="['Developer', 'PlatformAdmin', 'OrganizationAdmin']">
    <button
      nz-button
      nz-popconfirm
      nzPopconfirmTitle="Wirklich löschen?"
      (nzOnConfirm)="deleteSelected()"
      [nzLoading]="isProcessing | async">
      <i class="text-red" nz-icon nzType="delete" nzTheme="outline"></i>
      {{ 'DOCUMENTLIST.softDelete' | translate }}
    </button>
  </ng-container>

  <ng-container *aclIf="['Developer']">
    <button
      nz-button
      nz-popconfirm
      nzPopconfirmTitle="Wirklich löschen? Dies wird die zugehörigen EINGANGSDOKUMENTE UNWIDERRUFLICH löschen."
      (nzOnConfirm)="hardDeleteSelected()"
      [nzLoading]="isProcessing | async">
      <i class="text-red mr-sm" nz-icon nzType="info-circle" nzTheme="outline"></i>
      <i class="text-red" nz-icon nzType="delete" nzTheme="outline"></i>
      {{ 'DOCUMENTLIST.hardDelete' | translate }}
    </button>
  </ng-container>

  <ng-container *aclIf="['Developer', 'PlatformAdmin']">
    <button
      *ngIf="showSnooze"
      nz-button
      nz-popconfirm
      [nzPopconfirmTitle]="togglePopupTitle()"
      (nzOnConfirm)="toggleSnoozeSelected()"
      [nzLoading]="isProcessing | async">
      <div class="snooze anticon"></div>
      {{ 'DOCUMENTLIST.toggleSnooze' | translate }}
    </button>

    <button nz-button [nzLoading]="isProcessing | async" nz-dropdown [nzDropdownMenu]="menu" nzTrigger="click">
      <i class="blue" nz-icon nzType="robot" nzTheme="outline"></i>
      {{ 'DOCUMENTLIST.forceStatechange' | translate }}
      <nz-dropdown-menu #menu="nzDropdownMenu">
        <ul nz-menu nzSelectable>
          <li *ngFor="let state of triggerableStates" nz-menu-item (click)="forceDocumentStateChange(state)">
            <i class="mr-sm" nz-icon nzType="robot" nzTheme="outline"></i>
            {{ state }}
          </li>
        </ul>
      </nz-dropdown-menu>
    </button>
  </ng-container>

  <ng-container *aclIf="['OrganizationAdmin']">
    <button [nzLoading]="isProcessing | async" nz-button *ngIf="showResetButton" nzTrigger="click" (click)="toVerification()">
      <span class="blue" nz-icon nzType="rollback" nzTheme="outline"></span>
      {{ 'DOCUMENT.sendToVerification' | translate }}
    </button>
  </ng-container>

  <ng-container *aclIf="['Developer', 'PlatformAdmin', 'OrganizationAdmin']">
    <button
      *ngIf="showTreeButton"
      nz-button
      nz-tooltip
      nzTooltipTitle="{{ 'DOCUMENTLIST.tree' | translate }}"
      (click)="treeVisible = !treeVisible">
      <div class="list-tree anticon"></div>
      {{ 'DOCUMENTLIST.tree' | translate }}
    </button>
  </ng-container>

  <button nz-button (click)="copyIds()" nzTrigger="click">
    <i class="blue" nz-icon nzType="copy" nzTheme="outline"></i>
    ID{{ selectedIds.length > 1 ? 's' : '' }}
  </button>
</div>

<app-document-tree-viewer-drawer
  *ngIf="showTreeButton"
  [(visible)]="treeVisible"
  [documents]="selection"
  [showRootByDefault]="true"></app-document-tree-viewer-drawer>
