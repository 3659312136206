<ng-container *ngIf="showNameAndMail; else avatar">
  <nz-space nzSize="middle" id="avatar-component">
    <ng-container *ngTemplateOutlet="avatar"></ng-container>

    <!-- TEXT -->
    <ng-container *ngIf="showNameAndMail">
      <nz-space *nzSpaceItem nzDirection="vertical" [nzSize]="0">
        <strong *nzSpaceItem>{{ userName || userMail }}</strong>
        <span id="avatar-mail" *nzSpaceItem>{{ userMail }}</span>
      </nz-space>
    </ng-container>
  </nz-space>
</ng-container>

<!-- AVATAR -->
<ng-template #avatar>
  <nz-avatar
    [class.avatar-group-style]="isInsideAvatarGroup && index != 0"
    [nzText]="initials"
    [nzSize]="nzSize || (showNameAndMail ? 'large' : 'default')"
    [ngStyle]="colorStyle"></nz-avatar>
</ng-template>
