import { APP_INITIALIZER, NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/cache';
import { environment } from '@env/environment';
import { ApolloModule, APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ApolloOptionsService } from './apollo-options.service';
import { relayStylePagination } from './pagination-caching';

export function createApollo(apolloOptionsService: ApolloOptionsService) {
  return () => {
    console.log('Create Apollo. ....');
    const graphql_data_url = `${environment.api.baseUrl}graphql`;
    const backend_url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${graphql_data_url}`;
    const websocket_url = window.location.protocol === 'https:' ? backend_url.replace('https', 'wss') : backend_url.replace('http', 'ws');
    return apolloOptionsService
      .create(
        'data',
        backend_url,
        websocket_url,
        {
          watchQuery: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'ignore'
          },
          query: {
            fetchPolicy: 'cache-first',
            errorPolicy: 'all'
          }
        },
        new InMemoryCache({
          addTypename: true, // default
          resultCaching: true, // default
          // resultCacheMaxSize: Math.pow(2, 12), // The limit of the number of result objects that will be retained in memory to speed up repeated reads to the cache. The default value is `Math.pow(2, 16)`.
          typePolicies: {
            Query: {
              fields: {
                documentClasses: relayStylePagination(['filter'])
              }
            }
          }
        })
      )
      .then(() => {
        console.log('Create Apollo for frontend. ....');
        const graphql_frontend_url = `${environment.api.baseUrl}graphql-frontend`;
        const backend_url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${graphql_frontend_url}`;
        const websocket_url =
          window.location.protocol === 'https:' ? backend_url.replace('https', 'wss') : backend_url.replace('http', 'ws');
        return apolloOptionsService
          .create(
            'frontend',
            backend_url,
            websocket_url,
            {
              watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'ignore'
              },
              query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all'
              }
            },
            new InMemoryCache({
              addTypename: false
            })
          )
          .then(() => {
            console.log('Create Apollo for history. ....');
            const graphql_history_url = `${environment.api.baseUrl}graphql-history`;
            const backend_url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}${graphql_history_url}`;
            const websocket_url =
              window.location.protocol === 'https:' ? backend_url.replace('https', 'wss') : backend_url.replace('http', 'ws');
            return apolloOptionsService.create(
              'history',
              backend_url,
              websocket_url,
              {
                watchQuery: {
                  fetchPolicy: 'no-cache',
                  errorPolicy: 'ignore'
                },
                query: {
                  fetchPolicy: 'no-cache',
                  errorPolicy: 'all'
                }
              },
              new InMemoryCache({}),
              true
            );
          });
      });
  };
}

export function getApolloOptions(apolloOptionsService: ApolloOptionsService) {
  // Fail if it was not created successfully
  return {
    frontend: apolloOptionsService.apolloOptions['frontend'],
    data: apolloOptionsService.apolloOptions['data'],
    history: apolloOptionsService.apolloOptions['history']
  };
}

// Async apollo initialization according to: https://stackoverflow.com/questions/66095489/angular-usefactory-return-async-function-in-module
// Make apollo link with with scalars
@NgModule({
  imports: [ApolloModule],
  providers: [
    ApolloOptionsService,
    {
      provide: APP_INITIALIZER,
      useFactory: createApollo,
      deps: [ApolloOptionsService, HttpLink],
      multi: true
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [ApolloOptionsService, HttpLink],
      useFactory: getApolloOptions
    }
  ]
})
export class GraphQLModule {}
