import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule, Type } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DelonACLModule } from '@delon/acl';
import { DelonFormModule } from '@delon/form';
import { AlainThemeModule } from '@delon/theme';
import { MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';

import { GraphQLModule } from '../graphql/graphql.module';
import { DocumentClassIdentifierModalComponent } from './components/document-class-identifier-modal/document-class-identifier-modal.component';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { SubdocumentDrawerComponent } from './components/document-list/subdocument-drawer/subdocument-drawer.component';
import { DocumentModifierBarComponent } from './components/document-modifier-bar/document-modifier-bar.component';
import { DocumentTreeViewerDrawerComponent } from './components/document-tree-viewer-drawer/document-tree-viewer-drawer.component';
import { DocumentTreeViewerComponent } from './components/document-tree-viewer/document-tree-viewer.component';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { EntityListComponent } from './components/entity-list/entity-list.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { UploadAreaComponent } from './components/upload-area/upload-area.component';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { DateValidatorDirective } from './directives/date-validator.directive';
import { DigitLimitDirective } from './directives/digit-limit.directive';
import { ObserveElementDirective } from './directives/observe-element.directive';
import { AddTokenPipe } from './pipes/add-token.pipe';
import { DurationPipe, IntervalToDurationPipe } from './pipes/duration.pipe';
import { FilterUndeletedDocumentClassesPipe } from './pipes/filter-undeleted-document-classes.pipe';
import { FilterUndeleted } from './pipes/filter-undeleted.pipe';
import { FormatCeSourcePipe } from './pipes/format-ce-source.pipe';
import { FormatCeTypePipe } from './pipes/format-ce-type.pipe';
import { FormatDocumentId } from './pipes/format-document-id.pipe';
import { FormatDocumentName } from './pipes/format-document-name.pipe';
import { FormatDocumentState } from './pipes/format-document-state.pipe';
import { FormatTimeDistancePipe } from './pipes/format-time-distance.pipe';
import { GlobalToLocalIdentifierPipe } from './pipes/global-to-local-identifier.pipe';
import { NoResourceSanitizePipe } from './pipes/no-sanitize.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SecurePipe } from './pipes/secure.pipe';
import { SecureAsStringPipe } from './pipes/secureAsString.pipe';
import { TranslateScopePipe } from './pipes/translate-scope.pipe';
import { OrganizationService } from './services/organization.service';
import { UserService } from './services/user.service';
import { SHARED_DELON_MODULES } from './shared-delon.module';
import { SHARED_ZORRO_MODULES } from './shared-zorro.module';

// #region third libs

const THIRDMODULES: any[] = [
  DragDropModule,
  MonacoEditorModule,
  OverlayModule,
  NgxJsonViewerModule,
  NgxEchartsModule.forRoot({
    echarts: () => import('echarts')
  }),
  MarkdownModule.forRoot({
    markedOptions: {
      provide: MarkedOptions,
      useValue: {
        gfm: true,
        breaks: false,
        pedantic: false,
        smartLists: true,
        smartypants: false
      }
    }
  }),
  TranslateModule,
  LazyLoadImageModule
];

// #endregion

// #region your components & directives

const COMPONENTS: Array<Type<void>> = [
  DocumentListComponent,
  EntityListComponent,
  UploadAreaComponent,
  UserAvatarComponent,
  MaintenanceComponent,
  DocumentViewerComponent,
  DocumentClassIdentifierModalComponent,
  SubdocumentDrawerComponent,
  DocumentModifierBarComponent,
  DocumentTreeViewerDrawerComponent,
  DocumentTreeViewerComponent
];
const DIRECTIVES: Array<Type<void>> = [
  DurationPipe,
  IntervalToDurationPipe,
  NoResourceSanitizePipe,
  DigitLimitDirective,
  FormatDocumentName,
  FormatDocumentId,
  FormatDocumentState,
  FormatTimeDistancePipe,
  FilterUndeleted,
  TranslateScopePipe,
  DateValidatorDirective,
  SecurePipe,
  SecureAsStringPipe,
  FilterUndeletedDocumentClassesPipe,
  SafePipe,
  FormatCeTypePipe,
  FormatCeSourcePipe,
  ObserveElementDirective,
  AddTokenPipe
];

// #endregion

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    AlainThemeModule.forChild(),
    DelonACLModule,
    DelonFormModule,
    GraphQLModule,
    ...SHARED_DELON_MODULES,
    ...SHARED_ZORRO_MODULES,
    // third libs
    ...THIRDMODULES
  ],
  declarations: [
    // your components
    ...COMPONENTS,
    ...DIRECTIVES,
    GlobalToLocalIdentifierPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlainThemeModule,
    DelonACLModule,
    DelonFormModule,
    ...SHARED_DELON_MODULES,
    ...SHARED_ZORRO_MODULES,
    // third libs
    ...THIRDMODULES,
    // your components
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  providers: [OrganizationService, UserService]
})
export class SharedModule {}
