import { Injectable } from '@angular/core';
import { ACLService } from '@delon/acl';
import { QueryRef } from 'apollo-angular';
import omit from 'lodash/omit';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateOneOrganizationGQL,
  Exact,
  Organization,
  OrganizationByIdentifierGQL,
  OrganizationsGQL,
  OrganizationsQuery,
  UpdatedOneOrganizationGQL,
  UpdateManyOrganizationsGQL
} from 'src/app/graphql/data-graphql';

@Injectable()
export class OrganizationService {
  constructor(
    private organizations: OrganizationsGQL,
    private organizationByIdentifier: OrganizationByIdentifierGQL,
    public updateManyOrganizations: UpdateManyOrganizationsGQL,
    private updatedOneOrganization: UpdatedOneOrganizationGQL,
    public createOneOrganization: CreateOneOrganizationGQL,
    private acl: ACLService,
    private modal: NzModalService
  ) {}

  getOrganizationObservable() {
    const organizationsQuery = this.organizations.watch();
    const organizations$ = organizationsQuery.valueChanges.pipe(map(res => (res.data.organizations as Organization[]) ?? []));
    this.updatedOneOrganization.subscribe().subscribe(() => {
      organizationsQuery.refetch();
    });
    return organizations$;
  }

  update(organization: Partial<Organization>) {
    if (!organization.identifier) return;
    return this.updateManyOrganizations.mutate({
      input: { filter: { identifier: { eq: organization.identifier } }, update: omit(organization, ['users']) }
    });
  }

  getById(identifier: string) {
    return this.organizationByIdentifier.fetch({ identifier });
  }
}
