<app-document-modifier-bar
  *ngIf="checklistSelection.selected.length > 0"
  [selection]="checklistSelection"
  [showSnooze]="false"
  [showResetButton]="true"></app-document-modifier-bar>

<nz-spin style="min-height: 200px" [nzSpinning]="isLoading">
  <nz-tree-virtual-scroll-view [nzTreeControl]="treeControl" [nzDataSource]="dataSource" style="height: 80vh">
    <nz-tree-node *nzTreeNodeDef="let node" nzTreeNodeIndentLine>
      <ng-container *ngIf="checkTypeNode(node)">
        <nz-tree-node-toggle *ngIf="node.children.length > 0">
          <span nz-icon nzType="caret-down" nzTreeNodeToggleRotateIcon></span>
        </nz-tree-node-toggle>
        <nz-tree-node-checkbox
          [nzChecked]="checklistSelection.isSelected(node)"
          (nzClick)="leafItemSelectionToggle(node)"></nz-tree-node-checkbox>
        <nz-tree-node-option [nzSelected]="documents.isSelected(node)" *let="stateColor[node.state] as color">
          <span nz-icon nzType="file" style="color: {{ color }}; margin-right: 4px" nzTheme="outline"></span>
          <span style="font-weight: bold">{{ node.document_class?.display_name }}</span>
          <span *ngIf="node.children.length > 0" style="margin-left: 8px">({{ node.children.length }})</span>
          <a nz-tooltip="Navigiere zum Dokument" [href]="'/documents/' + node.id" target="_blank" style="color: inherit; margin-left: 8px">
            <i class="mr-sm" nz-icon nzType="link" nzTheme="outline"></i>
          </a>
          <span
            *ngIf="node.children.length > 0"
            nz-tooltip="Alle ausklappen/einklappen"
            (click)="toggleLevel(node)"
            nz-icon
            [nzType]="treeControl.isExpanded(node) ? 'minus-square' : 'plus-square'"
            nzTheme="outline"></span>
          <span *ngIf="!node.document_value" style="font-style: italic; color: {{ color }}">
            <nz-divider nzType="vertical"></nz-divider>
            <nz-spin
              *ngIf="
                [
                  DocumentState.AwaitingPrediction,
                  DocumentState.AwaitingSeparation,
                  DocumentState.AwaitingBlindProcessingDecision,
                  DocumentState.AwaitingExport,
                  DocumentState.AwaitingPreprocessing
                ].includes(node.state)
              "
              nzSimple
              style="display: inline-block"
              [nzIndicator]="indicatorTemplate"></nz-spin>
            {{ node.state }}
          </span>

          <span class="meta-info">
            <nz-divider nzType="vertical"></nz-divider>
            <span nz-icon nzType="copy" nzTheme="outline"></span>
            {{ node.page_count }}
          </span>

          <span class="meta-info" *ngIf="node.prediction && node.prediction?.confirmedness != 1.0">
            <nz-divider nzType="vertical"></nz-divider>
            <span *ngIf="node.prediction.confirmedness == 0.0" style="color: rgb(82, 196, 26)" [nz-tooltip]="node.prediction.source">
              {{ 'DOCUMENT.TREE.ai' | translate }}
            </span>
            <span *ngIf="node.prediction.confirmedness > 0.0" style="color: #1890ff" [nz-tooltip]="node.prediction.user_id">
              {{ 'DOCUMENT.TREE.user' | translate }}
            </span>
          </span>

          <span class="meta-info">
            <nz-divider nzType="vertical"></nz-divider>
            {{ 'DOCUMENTLIST.created' | translate }} {{ node.created | formatTimeDistance }}, {{ 'DOCUMENTLIST.lastEdited' | translate }}
            {{ node.updated | formatTimeDistance }}
          </span>

          <nz-tag nzColor="blue" class="ml-sm" *ngIf="node.document_value">{{ node.document_value }}</nz-tag>
        </nz-tree-node-option>
      </ng-container>
    </nz-tree-node>
  </nz-tree-virtual-scroll-view>
  <ng-template #indicatorTemplate><span nz-icon nzType="loading"></span></ng-template>
</nz-spin>
