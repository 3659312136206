import { Injectable } from '@angular/core';
import { ModalHelper } from '@delon/theme';
import { QueryRef } from 'apollo-angular';
import * as Apollo from 'apollo-angular';
import keyBy from 'lodash/keyBy';
import { merge, Observable, of } from 'rxjs';
import { filter as rxjsFilter, map, shareReplay, skip, switchMap, delay } from 'rxjs/operators';
import {
  DocumentClassesGQL,
  SortDirection,
  DocumentClassSortFields,
  DocumentClass,
  CreateOneDocumentClassGQL,
  UpdateManyDocumentClassesGQL,
  AutoPredictorMode,
  UpdatedOneDocumentClassGQL,
  CreatedDocumentClassGQL,
  DocumentClassCreateDto,
  DocumentClassUpdateDto,
  DocumentClassFilter,
  DocumentClassSort,
  DocumentClassesByRegexGQL
} from 'src/app/graphql/data-graphql';
import { CursorPaging } from 'src/app/graphql/frontend-data-graphql';
import { DocumentClassIdentifierModalComponent } from 'src/app/shared/components/document-class-identifier-modal/document-class-identifier-modal.component';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class DocumentClassService {
  constructor(
    public documentClassesRequest: DocumentClassesGQL,
    public documentClassByRegex: DocumentClassesByRegexGQL,
    public updatedOneDocumentClass: UpdatedOneDocumentClassGQL,
    public createDocumentClass: CreatedDocumentClassGQL,
    public createOneDocumentClass: CreateOneDocumentClassGQL,
    public updateManyDocumentClasses: UpdateManyDocumentClassesGQL,
    private authService: AuthService,
    private modalHelper: ModalHelper
  ) {}

  watchDocumentClasses(
    filter: DocumentClassFilter,
    paging: CursorPaging = { first: 50 },
    sorting: DocumentClassSort[] | DocumentClassSort = { field: DocumentClassSortFields.DisplayName, direction: SortDirection.Asc },
    withDetail = true,
    simple = false,
    childrenFilter: DocumentClassFilter = {}
  ) {
    return this.authService.organization$
      .pipe(
        rxjsFilter(o => o != undefined),
        delay(10),
        map(org =>
          this.documentClassesRequest.watch({
            filter: filter,
            paging: paging,
            sorting: sorting,
            withDetail: withDetail,
            simple: simple,
            childrenFilter: childrenFilter
          })
        )
      )
      .pipe(
        switchMap(documentClassQuery => {
          merge(this.updatedOneDocumentClass.subscribe(), this.createDocumentClass.subscribe()).subscribe(() => {
            documentClassQuery.refetch();
          });
          return documentClassQuery.valueChanges.pipe(
            map(res => res.data?.documentClasses.edges.map(dt => dt.node as DocumentClass) ?? [])
          );
        }),
        map(classes => {
          return keyBy(classes, dc => dc.identifier);
        }),
        shareReplay(1)
      );
  }

  getById(identifier: string): Observable<DocumentClass> {
    const docObservable = this.documentClassesRequest.watch({ filter: { identifier: { eq: identifier } } });
    return docObservable.valueChanges.pipe(map(res => res.data.documentClasses.edges?.map(e => e.node as DocumentClass)[0]));
  }

  create(documentClass: DocumentClassCreateDto): Observable<DocumentClass> {
    return this.createOneDocumentClass
      .mutate({
        input: { documentClass: documentClass }
      })
      .pipe(map(res => res.data?.createOneDocumentClass as DocumentClass));
  }

  update(identifier: string, documentClass: DocumentClassUpdateDto): Observable<DocumentClass> {
    return this.updateManyDocumentClasses
      .mutate({
        input: { filter: { identifier: { eq: identifier } }, update: documentClass }
      })
      .pipe(
        switchMap(res =>
          this.documentClassesRequest
            .fetch({ filter: { identifier: { eq: identifier } }, withDetail: true })
            .pipe(map(res => res.data.documentClasses.edges[0].node as DocumentClass))
        )
      );
  }

  addSubclass(parent: DocumentClass) {
    return this.modalHelper.create(DocumentClassIdentifierModalComponent).pipe(
      switchMap(res => {
        return this.create(<DocumentClassCreateDto>{
          order_weight: 1,
          local_identifier: res.identifier,
          parent_id: parent.identifier,
          display_name: res.identifier,
          auto_predictor_mode: AutoPredictorMode.Disabled,
          always_include_in_blind_processing_decision: false
        });
      })
    );
  }
}
