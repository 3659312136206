import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  TrackByFunction,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { STChange, STColumn, STComponent, STData, STPage, STReq } from '@delon/abc/st';
import { QueryRef } from 'apollo-angular';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import { BehaviorSubject, Subject, Subscription, Observable, interval } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Maybe, PlatformRole, SortDirection } from 'src/app/graphql/frontend-data-graphql';

import { AuthService } from '../../services/auth.service';
import { DocumentService } from '../../services/document.service';

export enum PagingMode {
  Offset,
  Cursor,
  CursorHistory
}

export interface IEntity {
  id?: Maybe<string>;
}

@Component({
  selector: 'app-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  constructor(private _viewContainerRef: ViewContainerRef, public auth: AuthService, public documentService: DocumentService) {}
  @Input()
  query: QueryRef<any, any> | null;
  @Input()
  archiveMode = false; // set to true, if the list should only show deleted items
  @Input()
  created$: Observable<IEntity>;
  @Input()
  updated$: Observable<IEntity>;
  @Input()
  deleted$: Observable<IEntity>;
  @Input()
  reload$: Observable<any>;
  @Input()
  clear$: Observable<boolean>;
  @Input()
  name?: string;
  @Input()
  getData?: (data: any) => any;
  @Input()
  columns: STColumn[];
  @Input()
  translateFilters: (column: STColumn, filter: any) => any;
  @Input()
  pageSize = 50;
  @Input()
  virtualItemSize = 52;
  @Input()
  pagingMode: PagingMode = PagingMode.Offset;
  @ViewChild('table', { static: false })
  table: STComponent;
  @Input()
  scrollY: string;
  @Output()
  readonly countChanged = new EventEmitter<number>();
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  readonly change = new EventEmitter<STChange>();
  @Input()
  sortDirection = SortDirection.Desc;
  @Input()
  columnTemplate?: TemplateRef<any>;
  @Output()
  readonly itemsChanged = new EventEmitter<any[]>();
  @Input()
  expand: TemplateRef<any>;
  @Input()
  id = 'id';
  @Input()
  showExpandForField: string;

  _scrollY = '500px';

  private destroy$ = new Subject();

  loading = false;
  data$: BehaviorSubject<STData[]> = new BehaviorSubject<STData[]>([]);
  tableData$: Observable<STData[]>;
  cursor?: string;
  hasNextPage = true;
  page: STPage = {
    front: false,
    show: false
  };
  filter: any = {};
  sorting: any;
  req: STReq = {};
  scrollIndex = 0;
  originalVariables: any;

  dateList: Date[];
  dateRateFilterVisible = false;
  existingSubscription: Subscription;
  totalCount: number;

  levelColors = {
    ERROR: 'red',
    WARNING: 'orange',
    INFO: 'grey'
  };
  JSON = JSON;
  PlatformRole = PlatformRole;

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) {
    const rect = this._viewContainerRef.element.nativeElement.getBoundingClientRect();
    if (!this.scrollY) {
      this._scrollY = `${window.innerHeight - rect.y - 54}px`;
    }
  }

  restoreScrollState() {
    setTimeout(() => {
      this.table.cdkVirtualScrollViewport.scrollToIndex(this.scrollIndex);
    }, 5);
  }

  highlight(entity: any) {
    if (!this.componentIsInactive) return;
    setTimeout(() => {
      const existingIndex = this.data$.value?.findIndex((e: any) => e[this.id] == entity[this.id]);
      this.table.setRow(existingIndex, { className: 'highlight' });
    });
  }
  componentIsInactive = false;
  _onReuseInit() {
    this.componentIsInactive = false;
  }
  _onReuseDestroy() {
    this.componentIsInactive = true;
  }

  ngOnInit(): void {
    this.tableData$ = this.data$.pipe(
      map(data => {
        data.forEach(d => {
          if (this.showExpandForField != undefined && !d[this.showExpandForField]) d.showExpand = false;
          if (this.selectedIds?.has(d[this.id])) d.checked = true;
        });
        return data;
      })
    );
    this.clear$?.subscribe(() => {
      this.data$.next([]);
    });

    interval(60000).subscribe(() => {
      this.data$.next([...this.data$.value]);
    });

    this.reload$?.subscribe(() => {
      delete this.cursor;
      this.data$.next([]);
      this.reload();
    });

    this.created$?.subscribe((data: IEntity) => {
      if (this.archiveMode === false) {
        this.addRow(data);
      }
    });

    this.updated$?.subscribe((data: IEntity) => {
      if (this.archiveMode === false) {
        this.updateRow(data);
      } else if ((data as any)?.deleted == undefined) {
        this.removeRow(data);
      }
    });

    this.deleted$?.subscribe((data: IEntity) => {
      if (this.archiveMode === false) {
        this.removeRow(data);
      } else {
        this.addRow(data);
      }
    });

    this.data$.subscribe(items => this.itemsChanged.emit(items));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.onResize();
    }, 1);
    this.table.change.pipe(takeUntil(this.destroy$)).subscribe(change => {
      if (change.filter) {
        if (this.translateFilters) {
          this.translateFilters(change.filter, this.filter);
        } else {
          change.filter?.filter?.menus?.forEach(m => {
            if (m.checked) {
              const element: any = (change.filter?.index as any)[0];
              if (this.pagingMode === PagingMode.CursorHistory) {
                this.filter[element] = m.value;
              } else if (this.pagingMode === PagingMode.Cursor) {
                this.filter[element] = {
                  eq: m.value
                };
              }
            }
          });
        }
        this.data$.next([]);
        delete this.cursor;
        this.reload();
      } else if (change.sort) {
        if (this.pagingMode === PagingMode.Cursor) {
          this.sorting = {
            field: (change.sort?.column?.index as any)[0],
            direction: change.sort.value === 'ascend' ? SortDirection.Asc : SortDirection.Desc
          };
        }
        delete this.cursor;
        this.data$.next([]);
        this.reload();
      }
    });

    setTimeout(() => {
      this.table.cdkVirtualScrollViewport?.scrolledIndexChange.pipe(takeUntil(this.destroy$)).subscribe(newScrollIndex => {
        if (newScrollIndex == undefined && this.scrollIndex) {
          this.table.cdkVirtualScrollViewport?.scrollToIndex(
            this.scrollIndex >= this.data$.value?.length ? this.data$.value?.length - 1 : this.scrollIndex
          );
          return;
        } else {
          this.scrollIndex = newScrollIndex;
        }

        const end = this.table.cdkVirtualScrollViewport.getRenderedRange().end;
        const total = this.table.cdkVirtualScrollViewport.getDataLength();
        if (end >= total && this.hasNextPage) {
          this.reload(total + 1);
        }
      });
      if (!this.table.cdkVirtualScrollViewport) {
        console.error('No scroll viewport available');
      }
    }, 1);
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['query'] && changes['query'].currentValue) {
      delete this.cursor;
      this.originalVariables = (this.query as any).obsQuery.variables;
      this.data$.next([]);
      if (this.existingSubscription) {
        this.existingSubscription.unsubscribe();
      }
      this.scrollIndex = 0;
      this.table?.cdkVirtualScrollViewport?.scrollToIndex(0);
      this.reload();
    }
  }

  reload(pageIndex: number = 1) {
    if (!this.query || this.loading) {
      return;
    }
    this.loading = true;
    let variables = cloneDeep(this.originalVariables);

    if (this.pagingMode === PagingMode.Offset) {
      variables = { ...variables, paging: { offset: (pageIndex - 1) * this.pageSize, limit: this.pageSize } };
    } else if (this.pagingMode === PagingMode.CursorHistory) {
      variables = { ...variables, ...this.filter, after: this.cursor, first: this.pageSize };
    } else if (this.pagingMode === PagingMode.Cursor) {
      variables = merge(
        variables,
        { filter: this.filter },
        { sorting: this.sorting },
        {
          paging: {
            after: this.cursor,
            first: this.pageSize
          }
        }
      );
    }

    const oldScrollIndex = this.scrollIndex;

    const fetchMoreResult = this.query.fetchMore({ variables }).then(fetchMoreResult => {
      const res = this.getData ? this.getData({ data: fetchMoreResult.data }) : get(fetchMoreResult.data, this.name as any) ?? '';
      if (this.pagingMode === PagingMode.Offset) {
        this.data$.next(res);
      } else if (this.pagingMode === PagingMode.Cursor || this.pagingMode === PagingMode.CursorHistory) {
        this.hasNextPage = res.pageInfo.hasNextPage;
        if (res.pageInfo.hasNextPage) {
          this.cursor = res.pageInfo.endCursor;
        }
        const newEntries = res.edges.map((e: any) => e.node);
        const newList = uniqBy([...(this.data$.value ?? []), ...newEntries], e => e[this.id]);
        this.data$.next(newList.length > 0 ? newList : []);
        this.totalCount = res.totalCount;
        this.countChanged.emit(this.totalCount);
      }
      this.loading = false;
      setTimeout(() => {
        this.table.cdkVirtualScrollViewport.scrollToIndex(oldScrollIndex);
      });
    });
  }

  virtualForTrackBy: TrackByFunction<{ id: string }> = (i: number, a: any) => a[this.id];

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  applyDateRangeFilter(name: string, dateList: Date[]) {
    this.dateRateFilterVisible = false;
    this.filter[name] = {
      between: {
        lower: dateList[0],
        upper: dateList[1]
      }
    };

    delete this.cursor;
    this.data$.next([]);
    this.reload();
  }

  trackByFn = (i: number, a: any) => a[this.id];

  private addRow(data: any) {
    let list = [...(this.data$.value ?? [])];
    const existingIndex = this.data$.value?.findIndex((e: any) => e[this.id] == data[this.id]);

    if (existingIndex > -1) {
      list[existingIndex] = data;
    } else {
      list = this.sortDirection === SortDirection.Desc ? [data, ...list] : [...list, data];
      this.totalCount++;
      this.countChanged.emit(this.totalCount);
    }
    this.data$.next(list);

    this.highlight(data);
  }

  private updateRow(data: any) {
    const list = [...(this.data$.value ?? [])];

    const existingIndex = list?.findIndex((e: any) => e[this.id] == data[this.id]);
    if (existingIndex > -1) {
      list[existingIndex] = data;
    }
    this.data$.next(list);
    this.highlight(data);
  }

  private removeRow(data: any) {
    const list = [...(this.data$.value ?? [])];
    const existingIndex = list.findIndex((e: any) => e[this.id] == data[this.id]);
    if (existingIndex > -1) {
      list.splice(existingIndex, 1);
      this.data$.next(list);
      this.totalCount--;
      this.countChanged.emit(this.totalCount);
    }
  }
  selectedIds: Set<string>;
  processChange(change: STChange) {
    if (change.type === 'checkbox') {
      this.selectedIds = new Set<string>(change?.checkbox?.map(e => e[this.id]) ?? []);
    }
    this.change.emit(change);
  }

  isTextOverflow(elementId: HTMLElement, takeParent = false): boolean {
    // const element = document.getElementById(elementId);
    // if (!element) return false;
    // return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
    if (takeParent && elementId.parentElement) elementId = elementId.parentElement;
    var curOverflow = elementId.style.overflow;
    if (!curOverflow || curOverflow === 'visible') elementId.style.overflow = 'hidden';
    var isOverflowing = elementId.clientWidth < elementId.scrollWidth || elementId.clientHeight < elementId.scrollHeight;
    elementId.style.overflow = curOverflow;
    return isOverflowing;
  }
}
